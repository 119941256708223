import { useCallback, useState } from 'react'
import {
  fetchAllDataHome,
  fetchProcessById,
} from 'views/screens/Platform-administrator/Home/services/fetch-home-data-platform-admin'
import { IDataprocessInfo } from 'types/common'
import { useNotification } from 'lib/context/notification.context'

interface TestPercentages {
  betesaPercent: number
  skillPercent: number
}

export const useGetResumeProcess = () => {
  const { getError } = useNotification()

  const [loadingDetailProcess, setLoadingDetailProcess] = useState<boolean>(false)
  const [processInfo, setProcessInfo] = useState<IDataprocessInfo | null>(null)
  const [testPercentages, setTestPercentages] = useState<TestPercentages>({
    betesaPercent: 0,
    skillPercent: 0,
  })

  const fetchProcessData = useCallback(async (selectedProcess: any | '') => {
    setLoadingDetailProcess(true)
    try {
      const response: IDataprocessInfo = !Array.isArray(selectedProcess) ? 
      await fetchProcessById(selectedProcess) : 
      await fetchAllDataHome();
      if (response && response.status === 'OK' ) {
        setProcessInfo(response)
        setTestPercentages({
          betesaPercent:
            typeof response.total_tests_generated?.betesaPercent === 'number'
              ? parseFloat(response?.total_tests_generated?.betesaPercent.toFixed(2))
              : 0,
          skillPercent:
            typeof response.total_tests_generated?.skillPercent === 'number'
              ? parseFloat(response?.total_tests_generated?.skillPercent.toFixed(2))
              : 0,
        })
      } else {
        getError('Error al obtener los datos del proceso seleccionado')
        throw new Error()
      }
    } finally {
      setLoadingDetailProcess(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { loadingDetailProcess, processInfo, testPercentages, fetchProcessData }
}
