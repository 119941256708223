import { FC, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MainLayout } from 'views/layouts'
import { ModulesQuestionContainer } from './styled'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import Input from 'views/components/UI/TextInput'
import Select from 'views/components/UI/Select'
import TitleDiv from 'views/components/UI/TitleDiv'
import { Button } from 'views/components/UI/Buttons'
import { useNotification } from 'lib/context/notification.context'
import { Row } from 'views/components/UI/Row'
import { generalDataSelector } from 'ducks/auth'
import { rootProcessSelector, setStepFiveTrigger, setUserListAdded } from 'ducks/createProcess' //setStepFive,
import { userSelector } from 'ducks/user'
import useCreateUser from 'hooks/users/useCreateUser/useCreateUser'
import { platformAdminRoutes } from 'router/routes'
import { validationSchema } from './schema'

const USER_EVALUATED_ROLE_TYPE = 4

const FormCreateUser: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { getError, getSuccess } = useNotification()
  const { createUser, loadingCreateUser } = useCreateUser()
  const { companyId } = useSelector(userSelector)
  const stepOneData = useSelector(rootProcessSelector).process.stepOne
  const stepFiveData = useSelector(rootProcessSelector).process.stepFive

  const { data: generalData } = useSelector(generalDataSelector)

  const [documenTypeOptions, setDocumentTypeOptions] = useState<any>([])

  useEffect(() => {
    if (generalData?.document_type) {
      const documentTypes = generalData.document_type.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      setDocumentTypeOptions(documentTypes)
    }
  }, [generalData?.document_type])

  const handleCreate = async (values: any) => {
    const responseCreateUser = await createUser({
      ...values,
      roleType: USER_EVALUATED_ROLE_TYPE,
      companyId,
      noSendEmail: true,
    })
    if (responseCreateUser.status === 'success') {
      const dataToSaveProcess: any = {
        users: {
          process_id: stepOneData.processId,
          users_id: [responseCreateUser.data.user_id],
        },
      }
      dispatch(setStepFiveTrigger.run(dataToSaveProcess))

      const previousUsers = stepFiveData?.userListAdded

      if (!previousUsers) {
        dispatch(
          setUserListAdded([
            {
              id: responseCreateUser.data.user_id,
              document: responseCreateUser.data.document,
              email: responseCreateUser.data.mail_address,
              first_name: responseCreateUser.data.user_name,
            },
          ])
        )
      } else {
        dispatch(
          setUserListAdded([
            ...previousUsers,
            {
              id: responseCreateUser.data.user_id,
              document: responseCreateUser.data.document,
              email: responseCreateUser.data.mail_address,
              first_name: responseCreateUser.data.user_name,
            },
          ])
        )
      }
      setTimeout(() => {
        getSuccess('Usuario creado correctamente')
        navigate(platformAdminRoutes.CreateUsers, { state: { newUserId: responseCreateUser.data.user_id } })
      }, 1000)
    } else {
      getError(`Error al crear el usuario: ${responseCreateUser?.message}`)
    }
  }

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        documentType: '',
        document: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
    >
      {({ values, errors, touched, handleChange, setFieldValue, handleBlur, handleSubmit }) => (
        <MainLayout>
          <TitleDiv>
            <h2 style={{ fontSize: 32 }}>Crear nuevo usuario</h2>
          </TitleDiv>
          <h4 style={{ margin: 0 }}>Información del usuario</h4>

          <form onSubmit={handleSubmit}>
            <ModulesQuestionContainer>
              <Input
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                name="firstName"
                label="Nombre"
                helperText={(touched.firstName && errors.firstName) || ''}
              />
              <Input
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                name="lastName"
                label="Apellido"
                helperText={(touched.lastName && errors.lastName) || ''}
              />
              <Input
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                name="email"
                label="Correo electrónico"
                helperText={(touched.email && errors.email) || ''}
              />
              <Select
                label="Tipo de documento"
                options={documenTypeOptions}
                value={values.documentType}
                onChange={(value: any) => {
                  setFieldValue('documentType', value)
                }}
                name="documentType"
                error={(touched.documentType && (errors.documentType as string)) || ''}
                hideNeverChoice
              />
              <Input
                value={values.document}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                name="document"
                label="Número de documento"
                helperText={(touched.document && errors.document) || ''}
              />
            </ModulesQuestionContainer>
            <Row alignItems="center" justifyContent="space-between">
              <span
                style={{ fontWeight: 'bold', color: '#2D749C', cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              >
                Cancelar
              </span>
              <Button type="submit" disabled={loadingCreateUser || Object.keys(errors).length > 0}>
                {loadingCreateUser ? 'Creando...' : 'Crear'}
              </Button>
            </Row>
          </form>
        </MainLayout>
      )}
    </Formik>
  )
}
export default FormCreateUser
