import axios from 'lib/axios'

export const deleteDevelopmentPlan = async (userTestId: number) => {
  const url = `${process.env.REACT_APP_MAIN_API}/feed_back/delete/${userTestId}`
  try {
    const response = await axios.put(url)
    return response.data
  } catch (error) {
    console.error(error)
  }
}
