import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { MainLayout } from 'views/layouts/'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import {
  Container,
  ChartContainer,
  SubContainerChart,
  RowContainer,
  SkillsContainer,
  ReportContainer,
  HeaderButtonsWrapper,
  WrapperInfo,
  Subtitle,
  InfoText,
  InfoUserContainer,
  ScoreSectionWrapper,
  ScoreWrapperInfo,
  CardInfoWithBorder,
  CompetenceResultWrapper,
  AdjustmentLevelText,
  HeaderInfoText,
  SeeResultsButtonWrapper,
  SubContainerChartText,
  TextDownload,
  ButtonContainer,
  DevelopmentButtonContainer,
  OrderedList,
  ListItem,
} from './styled'
import Radar from '../Graphics/Radar'
import { H2, H4 } from 'views/components/UI/Text'
import CheckboxLabels from 'views/components/UI/CheckboxLabels'
import { StrokedButton, BackButton } from 'views/components/UI/Buttons'
import CardDownloadResults from 'views/components/Cards/DownloadResults'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { useNotification } from 'lib/context/notification.context'
import getSkillResult from 'services/results/skills-result'
import sendReportToUser from 'services/reports/sendReportResultToUserEvaluated/sendReportToUser'
import { IResultsSkills } from 'types'
import { useDispatch } from 'react-redux'
import { cleanPercentiles, setEvaluatedUserId, setTestId } from '../../screens/super-admin/percentiles/store'
import { fetchUpdateUserTestAction } from 'views/screens/Platform-administrator/constants/fetch_process'
import useReports from 'hooks/use-reports'
import { platformAdminRoutes } from 'router/routes'
import { useTranslation } from 'react-i18next'
import { fetchFeedBack } from 'views/screens/Evaluated-User/services/fetch-development-plan-betesa'
import RollBackIcon from 'assets/icons/RollBackIcon'
import { deleteDevelopmentPlan } from 'views/screens/super-admin/services/delete-development-plan'
const MAX_COMPETENCES_SELECTED = 6
const MIN_COMPETENCES_SELECTED = 3

const ResultsSkills: React.FC = () => {
  const { t } = useTranslation()
  const location: any = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { getError, getSuccess, getWarning } = useNotification()
  const { userName, testId, userId, companyId } = location.state || {}
  const { token } = useSelector((state: any) => state.user)
  const { handleReportDownload } = useReports()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSendingReport, setIsSendingReport] = useState<boolean>(false)
  const [resultData, setResultData] = useState<IResultsSkills | null>(null)
  const [selectedCompetences, setSelectedCompetences] = useState(new Set<string>())
  const [userTestId, setUserTestId] = useState<number>(0)
  const [disablePlanButton, setDisablePlanButton] = useState<boolean>(false)
  const [participation, setParticipation] = useState<boolean>(false)
  const { role } = useSelector((state: any) => state.auth)
  const [processId, setProcessId] = useState(0)
  const idealProfileData = useMemo(() => {
    if (!resultData) return []
    const idealProfileMap = new Map(resultData.ideal_profile.map((item: any) => [item.skill_name, item.weight]))
    const data = Array.from(selectedCompetences).map((competence) => idealProfileMap.get(competence) || 0)

    return data.every((value: any) => value === 0) ? undefined : data
  }, [resultData, selectedCompetences])

  useEffect(() => {
    if (!userTestId) return

    fetchFeedBack(userTestId)
      .then((res: any) => {
        if (res.data.status === 'OK') {
          setDisablePlanButton(true)
        }
      })
      .catch(() => {
        setDisablePlanButton(false)
      })
  }, [userTestId])

  useEffect(() => {
    if (isLoading || !token || !userId || !testId) return
    dispatch(cleanPercentiles())
    dispatch(setTestId(testId))
    dispatch(setEvaluatedUserId(userId))
    setIsLoading(true)
    getSkillResult(token, userId, testId)
      .then((res: any) => {
        if (res.status === 'OK') {
          res.user_result.sort((a: any, b: any) => {
            if (b.overall_score === a.overall_score) {
              return a.skill_name.toLowerCase() > b.skill_name.toLowerCase() ? 1 : -1
            }
            return b.overall_score - a.overall_score
          })
          setResultData(res)
          setProcessId(res.process_id)
          setSelectedCompetences(
            new Set(res.user_result.slice(0, MAX_COMPETENCES_SELECTED).map((item: any) => item.skill_name))
          )
        } else {
          getError(`Error obteniendo los resultados de la prueba, ${res.message}`)
          setResultData(null)
          setSelectedCompetences(new Set<string>())
        }
      })
      .finally(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch])

  const handleSendReport = useCallback(async () => {
    if (isSendingReport) return
    try {
      setIsSendingReport(true)
      await sendReportToUser({ userId, companyId: companyId })
      setParticipation(true)
      console.log('resultData?.user_result[0].user_test_id', resultData?.user_result[0].user_test_id)
      setUserTestId(Number(resultData?.user_result[0].user_test_id))
      fetchUpdateUserTestAction(Number(userTestId), true)
      getSuccess('Reporte enviado correctamente')
    } catch (error) {
      console.error('error', error)
      getError('Error al intentar enviar el reporte, comunícate con soporte.')
      setIsSendingReport(false)
    }
  }, [isSendingReport, userId, companyId, resultData?.user_result, getSuccess, getError, userTestId])

  const handleCheckboxChange = useCallback(
    (competence: string) => {
      setSelectedCompetences((prevCompetences) => {
        const newCompetences = new Set(prevCompetences)
        if (newCompetences.has(competence)) {
          if (newCompetences.size <= MIN_COMPETENCES_SELECTED) {
            getError(t('global.notifications.addSkillsErrorMessage', { number: MIN_COMPETENCES_SELECTED }))
            return newCompetences
          }
          newCompetences.delete(competence)
        } else {
          if (newCompetences.size >= MAX_COMPETENCES_SELECTED) {
            getError(t('global.notifications.addSkillsErrorMessage', { number: MAX_COMPETENCES_SELECTED }))
          } else {
            newCompetences.add(competence)
          }
        }
        return newCompetences
      })
    },
    [getError, t]
  )

  const competencesLabels = useMemo(() => Array.from(selectedCompetences), [selectedCompetences])
  const competencesData = useMemo(() => {
    if (!resultData) return []
    setUserTestId(Number(resultData?.user_result[0].user_test_id))
    const dataMap = new Map(resultData.user_result.map((item: any) => [item.skill_name, item.overall_score]))
    return competencesLabels.map((competence) => dataMap.get(competence) || 0)
  }, [resultData, competencesLabels])

  const handleDisabledClick = () => {
    getWarning('El usuario tiene configurada la descarga de reporte automático o ya se autorizó el acceso')
  }

  const handlePlanDisabledClick = () => {
    getWarning(t('global.notifications.planAlreadyCreated'))
  }

  const handleCreatePlan = () => {
    const body = {
      companyId,
      processId,
      userId,
      nameProcess: resultData?.process_name,
      testData: [
        {
          testId,
          typeTest: 'Competencias',
          typeLabel: 'skills',
          userTestId,
          domination: 'Interpretable',
        },
      ],
    }
    navigate(platformAdminRoutes.DevelopmentPlan, {
      state: {
        body,
        userName,
        flag: true,
      },
    })
  }

  const handleDeletePlan = async () => {
    const response = await deleteDevelopmentPlan(userTestId)
    if (response.status === 'OK') {
      getSuccess('Plan de desarrollo restaurado correctamente')
      setDisablePlanButton(false)
    } else {
      getError('Error al restaurar el plan de desarrollo')
    }
  }

  return (
    <MainLayout>
      {!isLoading && resultData ? (
        <Container>
          <RowContainer justifyContent="space-between">
            <H2>
              {t('global.common.skillText')} - {userName}
            </H2>
            <HeaderButtonsWrapper>
              {role === 'platform_admin' || role === 'super_platform_admin' ? (
                <StrokedButton
                  disabled={disablePlanButton}
                  onClick={handleCreatePlan}
                  onDisabledClick={handlePlanDisabledClick}
                >
                  Crear plan de desarrollo
                </StrokedButton>
              ) : null}
              <StrokedButton
                onClick={handleSendReport}
                disabled={
                  resultData?.user_result[0].participant_report ||
                  participation ||
                  resultData?.user_result[0].access_report
                }
                onDisabledClick={handleDisabledClick}
              >
                Autorizar acceso a usuario
              </StrokedButton>
            </HeaderButtonsWrapper>
          </RowContainer>
          <RowContainer>
            <WrapperInfo>
              <H4>Datos de la Prueba</H4>
              <RowContainer>
                <Subtitle>Empresa:</Subtitle>
                <HeaderInfoText>{resultData?.company_name}</HeaderInfoText>

                <Subtitle>Nombre del Proceso:</Subtitle>
                <HeaderInfoText>{resultData?.process_name}</HeaderInfoText>

                <Subtitle>Fecha de realización:</Subtitle>
                <HeaderInfoText>{resultData?.result_date}</HeaderInfoText>
              </RowContainer>
            </WrapperInfo>
          </RowContainer>
          <InfoUserContainer>
            <ScoreSectionWrapper>
              <ScoreWrapperInfo>
                <CardInfoWithBorder>
                  <H4 margin="6px 0px 10px 0px">{t('global.common.skillText')} evaluadas</H4>
                  <OrderedList>
                    {resultData?.user_result.map((item: any, index: number) => (
                      <ListItem key={index}>
                        <CompetenceResultWrapper>
                          <Subtitle>{item.skill_name}: </Subtitle>
                          <InfoText>{`${item.overall_score} `}</InfoText>
                          {item?.scale_name && <InfoText>({`${item.scale_name}`})</InfoText>}
                        </CompetenceResultWrapper>
                      </ListItem>
                    ))}
                  </OrderedList>
                  <RowContainer>
                    <Subtitle>Ajuste con el perfil ideal:</Subtitle>
                    {resultData.level_competence || resultData.level_score || resultData.level_score === 0 ? (
                      <AdjustmentLevelText>
                        {`${resultData.level_competence} - ${Math.min(resultData.level_score, 100)}%`}
                      </AdjustmentLevelText>
                    ) : null}
                  </RowContainer>
                  <RowContainer>
                    <Subtitle>Nivel:</Subtitle>
                    <AdjustmentLevelText>{`${resultData.level}`}</AdjustmentLevelText>
                  </RowContainer>
                  <RowContainer>
                    {role === 'platform_admin' || role === 'super_platform_admin' ? null : (
                      <SeeResultsButtonWrapper>
                        <StrokedButton type="button">
                          <Link to="/percentiles/" state={resultData.level}>
                            Ver resultados
                          </Link>
                        </StrokedButton>
                      </SeeResultsButtonWrapper>
                    )}
                  </RowContainer>
                </CardInfoWithBorder>
              </ScoreWrapperInfo>
              <ButtonContainer>
                <ReportContainer>
                  <CardDownloadResults
                    title={t('global.common.skillText')}
                    type="skills"
                    onClick={() => handleReportDownload(userId, testId, processId, userTestId, 'skills')}
                    user_id={userId}
                    test_id={testId}
                    user_test_id={userTestId}
                    processId={processId}
                    planToDownload={disablePlanButton}
                    userName={userName}
                  />
                </ReportContainer>
                {disablePlanButton && (role === 'super_admin' || role === 'special_admin') && (
                  <DevelopmentButtonContainer onClick={handleDeletePlan}>
                    <RollBackIcon color="#2d749c" />
                    <TextDownload>Restaurar plan de desarrollo</TextDownload>
                  </DevelopmentButtonContainer>
                )}
              </ButtonContainer>
            </ScoreSectionWrapper>
          </InfoUserContainer>
          <ChartContainer>
            <SubContainerChart>
              <Radar
                userResultLabels={competencesLabels}
                userResultData={[]}
                userScoreResult={competencesData}
                idealProfileData={idealProfileData}
                labelResult="Usuario"
              />
            </SubContainerChart>
            <SubContainerChartText>
              <SkillsContainer>
                {resultData?.user_result.map((item: any, index: number) => {
                  return (
                    <CheckboxLabels
                      checked={selectedCompetences.has(item.skill_name)}
                      onChange={() => handleCheckboxChange(item.skill_name)}
                      key={index}
                      label={item.skill_name}
                    />
                  )
                })}
              </SkillsContainer>
            </SubContainerChartText>
          </ChartContainer>
        </Container>
      ) : (
        <>
          {!isLoading && !isSendingReport ? (
            <H2 style={{ color: 'rgb(198, 1, 107)', marginTop: '60px', minHeight: '80%' }}>
              Este usuario no ha contestado la prueba betesa
            </H2>
          ) : (
            <ModalLoader isLoading={isLoading} />
          )}
        </>
      )}
      <BackButton onClick={() => navigate(-1)} />
    </MainLayout>
  )
}

export default ResultsSkills
