import styled from 'styled-components'

interface IDiv {
  margin?: string
  justifyContent?: string
}

export const TopButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 0px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  max-width: 1200px;
  padding: 3.5%;
`

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 35px;
`

export const InfoUserContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
`

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 43%;
`

export const CardInfoWithBorder = styled.div`
  width: 115%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background-color: white;
  flex-direction: column;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px 0px;
  padding: 12px;
`

export const SkillsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
`

export const ReportContainer = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  height: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
`

export const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const SubContainerChart = styled.div`
  width: 65%;
  display: flex;
  padding: 0px 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const SubContainerChartText = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const RowContainer = styled.div<IDiv>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin: ${(props) => props.margin};
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
`
export const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
export const Subtitle = styled.p`
  margin-right: 4px;
  margin-bottom: 0px;
  font-size: 16px;
  color: #212b36;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0.00735em;
  color: #000000;
`

export const HeaderInfoText = styled(Subtitle)`
  margin-right: 24px;
  font-weight: 400;
`

export const InfoText = styled(Subtitle)`
  font-weight: 400;
`

export const ScoreSectionWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: auto;
`

export const ScoreWrapperInfo = styled(WrapperInfo)`
  width: 50%;
  margin-right: 10%;
`

export const CompetenceResultWrapper = styled(RowContainer)`
  gap: 4px;
`

export const AdjustmentLevelText = styled(InfoText)`
  text-transform: capitalize;
`

export const SeeResultsButtonWrapper = styled.div`
  width: 50%;
  margin-top: 24px;
`

export const TextDownload = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #2d749c;
  margin: 0px 0px 0px 8px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3% 0% 3% 3%;
`

export const DevelopmentButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const OrderedList = styled.ol``

export const ListItem = styled.li``
