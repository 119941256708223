import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  overflow-y: none;
`

export const ProfileIdeaInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-left: 250px;
  margin-bottom: 48px;
`

export const ProfileIdealGraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  height: 220px;
  gap: 20px;
  p {
    margin: 0;
    margin-top: -20px;
    font-size: 14px;
  }
  span {
    font-size: 12px;
    margin-top: 10px;
    font-weight: 700;
  }
`

export const WrapperInfoCompetences = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: space-between;
`

export const BoldText = styled.span`
  font-weight: 700;
`

export const BetesaBoldText = styled(BoldText)`
  margin-top: 30px;
`

export const BetesaEditText = styled.span<{ valid: boolean }>`
  margin: 30px 0 0 380px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: ${({ valid }) => (valid ? '#2D749C' : '#C2C2C2')};
  cursor: pointer;
  z-index: 1;

  // hover
  &:hover {
    opacity: 0.8;
    text-decoration: underline;
  }
`

export const AlertLevelText = styled.span`
  font-weight: 700;
  margin-top: -36px;
  margin-left: 110px;
`

export const List = styled.ol`
  width: 100%;
  padding: 0;
  margin: 0;
  padding-left: 20px;
`

export const ListItem = styled.li`
  width: 100%;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
`
