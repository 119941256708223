import { useState, useEffect, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import { RoleFamily, ALERT_LEVELS, DetailRoleFamily } from 'types/common'
import {
  Container,
  HeaderContent,
  RoleFamilyContentData,
  Row,
  Column,
  RoleFamilyContent,
  TextTitleAlertBetesa,
  TextWidthDotContainer,
  TextDot,
  EditRoleFamilyContainer,
  BetesaQuadrantsContainer,
  OrderedList,
  ListItem,
} from './styled'
import Select from 'views/components/UI/Select'
import { H4, P, SubtitleBold } from 'views/components/UI/Text'
import EditRoleFamily from 'views/containers/RoleFamily/editRoleFamily'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import CartesianAxisBrainQuadrant from 'views/components/CartesianAxisBrainQuadrant'
import getDetailRoleFamilyByName from 'services/rolFamily/fetchGetDetailRoleFamilyByName'
import useGetEditedRoleFamily from 'hooks/process/useGetEditedRoleFamily/useGetEditedRoleFamily'
import { setStepTwoTrigger, stepFourSelector, stepOneSelector, stepTwoSelector } from 'ducks/createProcess'
import { parseQuadrantFullNameToShortName } from 'utils/common'

interface ItemTextWidthDotProps {
  text: string
  title?: string
}

const TextWidthDot = (props: ItemTextWidthDotProps) => {
  const { text, title } = props
  return (
    <TextWidthDotContainer>
      {title && (
        <P marginLeft="10px" weight={500} marginRight="10px">
          {title}
        </P>
      )}
      <TextDot>{text}</TextDot>
    </TextWidthDotContainer>
  )
}

interface IButtonEditRoleFamilyProps {
  onClick: () => void
}

const ButtonEditRoleFamily = (props: IButtonEditRoleFamilyProps) => {
  const { onClick } = props
  return (
    <EditRoleFamilyContainer onClick={onClick}>
      <BorderColorOutlinedIcon />
      <P margin="0px 10px 0px 10px" fontWeight={700} color="#2D749C">
        Editar familia de rol
      </P>
    </EditRoleFamilyContainer>
  )
}

interface InfoRoleFamilyProps {
  onChangeEditingRoleFamily: (value: boolean) => void
  options: RoleFamily[]
  onSelectRole: (role: string) => void
  defaultRole?: string
  onEditRoleFamily: (data: any) => void
}

const InfoRoleFamily: FC<InfoRoleFamilyProps> = ({
  onChangeEditingRoleFamily,
  options,
  onSelectRole,
  defaultRole,
  onEditRoleFamily,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { handleGetEditedRoleFamily } = useGetEditedRoleFamily()
  const stepOneData = useSelector(stepOneSelector)
  const stepTwoData = useSelector(stepTwoSelector)
  const stepFourData = useSelector(stepFourSelector)
  const hasEditedRoleFamily = stepFourData?.isEditingRoleFamily

  const alertLevelLabels = Object.keys(ALERT_LEVELS)

  const alertLevelOptions = Object.values(ALERT_LEVELS).map((value: number[], index) => {
    return {
      value: `${value}`,
      label: alertLevelLabels[index],
    }
  })

  const [loading, setLoading] = useState(false)
  const [roleSelected, setRoleSelected] = useState(defaultRole ?? '')
  const [infoRoleFamily, setInfoRoleFamily] = useState<DetailRoleFamily | undefined>()
  const [editingRoleFamily, setEditingRoleFamily] = useState(false)

  function getAlertLevelName(alertLevel: number[]) {
    return alertLevelOptions.find((item) => item.value === `${alertLevel}`)?.label
  }

  useEffect(() => {
    if (editingRoleFamily === true) {
      onChangeEditingRoleFamily(true)
    } else {
      onChangeEditingRoleFamily(false)
    }
  }, [editingRoleFamily, onChangeEditingRoleFamily])

  useEffect(() => {
    const fetchDetailRoleFamilyByName = async (roleSelectedStr: string) => {
      try {
        setLoading(true)
        const detailRoleRoleFamily: any = await getDetailRoleFamilyByName(roleSelectedStr)

        if (detailRoleRoleFamily.status === 'OK') {
          if (stepTwoData.roleFamilyName && stepFourData.processId) {
            const dataRoleFamily: any = await handleGetEditedRoleFamily(stepOneData.processId)
            if (
              !dataRoleFamily?.alert_level_range &&
              !dataRoleFamily?.competences_ideal_profile &&
              !dataRoleFamily?.ideal_profile
            ) {
              setInfoRoleFamily(detailRoleRoleFamily.data)
            }

            const idealProfileEdited = dataRoleFamily.ideal_profile
            const competencesIdealProfile = dataRoleFamily.competences_ideal_profile

            if (competencesIdealProfile?.skill_id) {
              competencesIdealProfile.skill_id = competencesIdealProfile.skill_id.map((item: any) => {
                return {
                  ...item,
                  id: item.skill_id,
                  skill: item.skill_name,
                }
              })
            }

            const idealProfile = detailRoleRoleFamily.data.ideal_profile.map((item: any) => {
              const itemBetesaEdited = idealProfileEdited?.find(
                (itemEdited: any) =>
                  itemEdited.quadrant ===
                  (item.quadrant.length === 2 ? item.quadrant : parseQuadrantFullNameToShortName(item.quadrant))
              )
              if (itemBetesaEdited) {
                item.min = itemBetesaEdited.min
                item.max = itemBetesaEdited.max
                item.priority = itemBetesaEdited.priority
                item.alert_level_range = dataRoleFamily.alert_level_range
              }
              return item
            })

            const data = {
              ...detailRoleRoleFamily.data,
              ideal_profile: idealProfile,
              competence_skills:
                competencesIdealProfile === undefined
                  ? detailRoleRoleFamily.data.competence_skills
                  : competencesIdealProfile,
            }

            setInfoRoleFamily(data)
            onEditRoleFamily(data)
          } else {
            setInfoRoleFamily(detailRoleRoleFamily.data)
            onEditRoleFamily(detailRoleRoleFamily.data)
          }
        }
      } catch (error) {
        console.error('error al obtener la familia de rol', error)
      } finally {
        setLoading(false)
      }
    }

    if (roleSelected) {
      fetchDetailRoleFamilyByName(roleSelected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleSelected, handleGetEditedRoleFamily, hasEditedRoleFamily])

  return (
    <Container>
      <HeaderContent>
        <Select
          width="40%"
          options={options.map((item) => ({ value: item.name, label: item.name }))}
          value={roleSelected}
          label="Selecciona la familia de rol"
          onChange={(e: string) => {
            dispatch(
              setStepTwoTrigger.run({
                isRoleFamilySelected: true,
                processId: stepOneData.processId,
                roleFamilyName: e,
              })
            )
            setRoleSelected(e)
            onSelectRole(e)
          }}
          hideNeverChoice
        />

        {roleSelected && !editingRoleFamily && <ButtonEditRoleFamily onClick={() => setEditingRoleFamily(true)} />}
      </HeaderContent>
      {roleSelected && !loading ? (
        <>
          {!editingRoleFamily ? (
            <RoleFamilyContentData>
              <Row>
                <Column width="25%">
                  <SubtitleBold>Descripción general</SubtitleBold>
                </Column>
                <Column width="75%">
                  <P color="#797A7C">{infoRoleFamily?.role_families?.[0]?.description}</P>
                </Column>
              </Row>
              <Row>
                <Column width="25%">
                  <SubtitleBold>Cargos típicos:</SubtitleBold>
                </Column>
                <Column width="75%">
                  {infoRoleFamily &&
                    infoRoleFamily?.typical_positions?.map((item: any, index: number) => (
                      <TextWidthDot text={item.name} key={index} />
                    ))}
                </Column>
              </Row>
              <RoleFamilyContent>
                {infoRoleFamily && infoRoleFamily?.competence_skills?.length > 0 && (
                  <>
                    <Row>
                      <Column width="25%">
                        <SubtitleBold>Nivel</SubtitleBold>
                      </Column>
                      <Column width="75%">
                        <P color="#797A7C">
                          {
                            infoRoleFamily?.role_families?.filter((item: any) => item.category === 'Competencias')[0]
                              ?.level
                          }
                        </P>
                      </Column>
                    </Row>
                    <Row>
                      <Column width="25%">
                        <SubtitleBold>Habilidades generales asociadas:</SubtitleBold>
                      </Column>
                      <Column width="75%">
                        {infoRoleFamily?.family_skills?.map((item: any, index: number) => (
                          <TextWidthDot text={item.name} key={index} />
                        ))}
                      </Column>
                    </Row>
                    <Row>
                      <Column width="25%">
                        <SubtitleBold>Diagnósticos sugeridos:</SubtitleBold>
                      </Column>
                      <Column width="75%">
                        {infoRoleFamily?.ideal_profile?.length > 0 && <TextWidthDot text="Betesa" />}
                        {infoRoleFamily?.competence_skills.length && (
                          <TextWidthDot text={t('global.common.skillText')} />
                        )}
                      </Column>
                    </Row>
                    <H4 margin="16px 0px 8px 0px">Prueba de {t('global.common.skillText')}</H4>
                    {infoRoleFamily?.competence_skills?.length > 0 && (
                      <Row>
                        <Column width="25%">
                          <SubtitleBold>Perfil ideal:</SubtitleBold>
                        </Column>
                        <Column width="75%">
                          <OrderedList>
                            {infoRoleFamily?.competence_skills.map((item: any, index: number) => (
                              <ListItem key={`ideal-profile-skills-${index}`}>
                                <TextWidthDot
                                  title={item.skill || item.skill_name}
                                  text={`Superior a ${item.weight}`}
                                  key={index}
                                />
                              </ListItem>
                            ))}
                          </OrderedList>
                        </Column>
                      </Row>
                    )}
                  </>
                )}
                {infoRoleFamily && infoRoleFamily?.ideal_profile.length > 0 && (
                  <>
                    <H4 margin="10px 0px 0px 0px">Prueba de Betesa</H4>
                    <BetesaQuadrantsContainer>
                      <TextTitleAlertBetesa>
                        Nivel de alerta: {getAlertLevelName(infoRoleFamily.ideal_profile[0].alert_level_range)}
                      </TextTitleAlertBetesa>
                      <CartesianAxisBrainQuadrant style={{ width: '450px' }} data={infoRoleFamily.ideal_profile} />
                    </BetesaQuadrantsContainer>
                  </>
                )}
              </RoleFamilyContent>
            </RoleFamilyContentData>
          ) : (
            <EditRoleFamily
              infoRoleFamily={infoRoleFamily}
              onSaved={() => {
                setEditingRoleFamily(false)
                onChangeEditingRoleFamily(false)
                if (onEditRoleFamily) onEditRoleFamily(infoRoleFamily)
              }}
              onCancel={() => {
                setEditingRoleFamily(false)
              }}
            />
          )}
        </>
      ) : (
        <ModalLoader isLoading={loading} />
      )}
    </Container>
  )
}

export default InfoRoleFamily
