import { useState, useEffect, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import MainLayout from 'views/layouts/Main'
import BluesiteTable from 'views/components/UI/BluesiteTable'
import ModalSkills from './modal-skills'
import {
  ActionAddSkillCreateProcess,
  ActionDeleteSkillCreateProcess,
} from 'views/components/UI/BluesiteTable/Actions/GridActions'
import { Container, StepsContainer, NextButtonContainer, Row } from './styled'
import { H2, H4, SubtitleBold } from 'views/components/UI/Text'
import MinimumRecord from './MinimumScore/skill-minimun-score'
import Steps from 'views/components/Stepper'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { Button, BackButton } from 'views/components/UI/Buttons'
import { useNotification } from 'lib/context/notification.context'
import Select from 'views/components/UI/Select'
import { STEPS_NEW_PROCESS } from '../constants'
import { platformAdminRoutes } from 'router/routes'
import { rootProcessSelector, setStepFourTrigger, stepThreeSelector, stepFourSelector } from 'ducks/createProcess'
import { generalDataSelector } from 'ducks/auth'
import useGetSkills from 'hooks/skills/useGetSkills/useGetSkills'
import { BETESA_CATEGORY_ID } from 'constants/global.constants'
import getSkillsByFilters from 'services/common/skills/getByFilters'
import { Skill, SkillWithMinRecord } from 'types/common'
import { HEADERS, CompetencesIdealProfile } from './constants'
import { Pagination, DEFAULT_LIMIT } from 'constants/global.constants'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

interface Filters {
  levelId: number
  groupId?: number
  name?: string
  page: number
}

const MIN_WEIGHT = 0
const MIN_SKILLS = 3
const MAX_SKILLS = 12

function ProcessSkills() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { getSkills } = useGetSkills()
  const { getWarning, getError, getSuccess } = useNotification()
  const dataStepFour = useSelector(stepFourSelector)

  const { process } = useSelector(rootProcessSelector)
  const stepThreeData = useSelector(stepThreeSelector)
  const { data: generalData } = useSelector(generalDataSelector)
  const groupOptions = useMemo(
    () =>
      generalData?.group
        ?.filter((group: any) => group.id !== BETESA_CATEGORY_ID)
        .map((group: any) => ({ label: group.name, value: group.id })) || [],
    [generalData]
  )
  const level: number = process.stepOne.level

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [pagination, setPagination] = useState<Pagination>({ totalPages: 1, totalResults: 0 })
  const [loading, setLoading] = useState<boolean>(false)
  const [skills, setSkills] = useState<Skill[]>([])
  const [skillsSelected, setSkillsSelected] = useState<any>([])
  const [skillsWithMinimumRecord, setSkillsWithMinimumRecord] = useState<SkillWithMinRecord[]>([])
  const [currentSkill, setCurrentSkill] = useState<any>(null)
  const [idSkillSelected, setIdSkillSelected] = useState<number[]>([])

  const [searchFilters, setSearchFilters] = useState<Filters>({ levelId: level, page: 1 })
  const [showModal, setShowModal] = useState(false)
  const [isEditedMinimumRecord, setIsEditingMinimumRecord] = useState<boolean>(false)

  useEffect(() => {
    const competencesIdealProfile: CompetencesIdealProfile[] = dataStepFour?.competencesIdealProfile || []
    if (competencesIdealProfile.length > 0) {
      const skillsWithWeight = competencesIdealProfile.map(async (item) => {
        try {
          const skillItem: any = await getSkills({ id: item.skill_id })
          return {
            id: item.skill_id,
            skill_id: item.skill_id,
            skill: skillItem[0].skill,
            skill_name: skillItem[0].skill,
            weight: item.weight,
          }
        } catch (error) {
          return error
        }
      })

      Promise.all(skillsWithWeight).then((resolvedSkills) => {
        setSkillsSelected(resolvedSkills)
      })
    }
  }, [dataStepFour, getSkills])

  useEffect(() => {
    if (!loading) {
      setLoading(true)
      const question = true
      getSkillsByFilters(searchFilters, searchFilters.page, DEFAULT_LIMIT, searchFilters.name, question)
        .then((response) => {
          setSkills(response.skills)
          setPagination({
            totalPages: response.total_pages,
            totalResults: response?.total_results || 0,
          })
        })
        .catch(() => {
          getError('Error al obtener las competencias')
        })
        .finally(() => {
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters, searchFilters.name, searchFilters.groupId, getError, searchFilters.page])

  // HANDLERS
  const handleAddSkill = useCallback(
    (skill: Skill) => {
      if (skillsSelected.length >= MAX_SKILLS) {
        return {}
      }
      setSkillsSelected([skill, ...skillsSelected])
      setIdSkillSelected([skill.id, ...idSkillSelected])
      getSuccess('Competencia agregada correctamente')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [skillsSelected, idSkillSelected]
  )

  const onDeleteSkill = useCallback(
    (skill: { id: number | string }) => {
      if (skillsSelected.length < 1) {
        getWarning('No hay competencias seleccionadas, debes volver a seleccionarlos')
      }
      const newSkillsSelected = skillsSelected.filter((item: any) => item?.id !== skill.id)
      setSkillsSelected(newSkillsSelected)
    },
    [skillsSelected, getWarning]
  )

  const handleViewNextSkill = useCallback(() => {
    const index = skills.findIndex((item) => item.id === currentSkill.id)
    if (index === skills.length - 1) {
      setCurrentSkill(skills[0])
    } else {
      setCurrentSkill(skills[index + 1])
    }
  }, [skills, currentSkill])

  const handleViewPreviousSkill = useCallback(() => {
    const index = skills.findIndex((item) => item.id === currentSkill.id)
    if (index === 0) {
      setCurrentSkill(skills[skills.length - 1])
    } else {
      setCurrentSkill(skills[index - 1])
    }
  }, [skills, currentSkill])

  const handleSearch = useCallback(
    (key: string, value: any) => {
      setSearchFilters({ ...searchFilters, [key]: value })
    },
    [searchFilters]
  )

  const handlePagination = useCallback(
    (page: number) => {
      setPagination({ ...pagination })
      setSearchFilters({ ...searchFilters, page })
    },
    [pagination, searchFilters]
  )

  const handleDefineMinimumRecord = useCallback(() => {
    if (skillsSelected.length < 1) {
      getWarning('No hay competencias seleccionadas, debes volver a seleccionarlos')
    }
    setIsEditingMinimumRecord(true)

    const skillsSelectedWithMinWeight = skillsSelected.map((skill: any) => {
      return {
        skill_id: skill.id,
        weight: skill.weight || MIN_WEIGHT,
      }
    })

    const dataStepFourToSave: any = {
      processId: stepThreeData?.processId,
      competencesIdealProfile: skillsSelectedWithMinWeight,
    }
    if (dataStepFour?.betesaIdealProfile?.betesa_skills) {
      dataStepFourToSave.betesaIdealProfile = dataStepFour.betesaIdealProfile
    }
    try {
      if (!skillsSelectedWithMinWeight.some((skill: any) => skill.weight === 0 || skill.weight === undefined)) {
        dispatch(setStepFourTrigger.run(dataStepFourToSave))
      } else {
        if (!isFirstRender) {
          setIsFirstRender(false)
          getWarning('Debes asignar un puntaje mínimo a todas las competencias')
        }
      }
    } catch (error) {
      console.error('error saving the skills and ideal profile', error)
    }
  }, [skillsSelected, stepThreeData, dispatch, getWarning, dataStepFour.betesaIdealProfile, isFirstRender])

  const hasInvalidSkills = () => {
    return skillsSelected.some((skill: any) => skill.weight <= MIN_WEIGHT || skill.weight === undefined)
  }

  const handleNextStepWithMinimumRecord = useCallback(() => {
    const skillsSelectedWithMinWeight = skillsSelected.map((skill: any) => {
      if (skill.weight <= 0) {
        getWarning(`La skill ${skill.skill} no tiene un weight válido. Por favor, añade un weight mayor a cero.`)
      }

      return {
        skill_id: skill.id,
        weight: skill.weight,
      }
    })

    if (skillsSelectedWithMinWeight.includes(undefined) || skillsSelectedWithMinWeight.includes({})) {
      return {}
    } else {
      if (hasInvalidSkills()) {
        getWarning(`Debes asignar un puntaje mínimo de ${MIN_WEIGHT + 1} a cada competencia`)
        return {}
      } else {
        if (skillsWithMinimumRecord.length > 0) {
          const dataStepFourToSave: any = {
            processId: stepThreeData?.processId,
            competencesIdealProfile: skillsSelectedWithMinWeight,
          }
          if (dataStepFour?.betesaIdealProfile?.betesa_skills) {
            dataStepFourToSave.betesaIdealProfile = dataStepFour.betesaIdealProfile
          }
          try {
            dispatch(setStepFourTrigger.run(dataStepFourToSave))
          } catch (error) {
            console.error('error saving the skills and ideal profile', error)
          } finally {
            navigate(platformAdminRoutes.CreateUsers)
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stepThreeData,
    dispatch,
    navigate,
    skillsWithMinimumRecord,
    dataStepFour.betesaIdealProfile,
    skillsSelected,
    getWarning,
  ])

  return (
    <MainLayout>
      <Container>
        {!isEditedMinimumRecord ? (
          <>
            <H2 margin="30px 0px 24px 0px">{t('global.common.skillText')}</H2>
            <StepsContainer>
              <Steps steps={STEPS_NEW_PROCESS} activeStep={3} />
            </StepsContainer>
            <Row justify="space-between">
              <H4
                style={{
                  width: '100%',
                }}
                margin="30px 0px 24px 0px"
              >
                {t('global.common.skillText')}
              </H4>
              <Row justify="flex-end" margin="0px 0px -120px 0px">
                <SubtitleBold style={{ margin: '0px 16px 0px 0px' }}>Grupos de competencias:</SubtitleBold>
                <Select
                  width="220px"
                  label="Grupos"
                  value={searchFilters?.groupId || ''}
                  onChange={(e) => {
                    if (e && e !== '0') {
                      handleSearch('groupId', e)
                    } else {
                      const filtersWithoutGroup = { ...searchFilters }
                      delete filtersWithoutGroup.groupId
                      setSearchFilters(filtersWithoutGroup)
                    }
                  }}
                  options={groupOptions}
                />
              </Row>
            </Row>
            <BluesiteTable
              data={skills}
              headers={HEADERS}
              searchBar={true}
              searchLabel="Buscar competencia:"
              onSearch={(e: any) => {
                if (e !== '') {
                  handleSearch('name', e)
                } else {
                  const filtersWithoutName = { ...searchFilters }
                  delete filtersWithoutName.name
                  setSearchFilters(filtersWithoutName)
                }
              }}
              totalPages={pagination.totalPages}
              handlePagination={handlePagination}
              loading={false}
              ActionComponent={ActionAddSkillCreateProcess}
              currentPage={searchFilters.page}
              extraDataAction={{
                handleAddSkill,
                skillsSelected,
                onViewSkill: (e: any) => {
                  setCurrentSkill(e)
                  setShowModal(true)
                },
              }}
            />
            {skillsSelected.length > 0 && (
              <BluesiteTable
                data={skillsSelected}
                headers={HEADERS}
                searchBar={false}
                searchLabel="Buscar competencia:"
                totalPages={0}
                currentPage={1}
                showNumbers
                limit={DEFAULT_LIMIT}
                loading={false}
                ActionComponent={ActionDeleteSkillCreateProcess}
                extraDataAction={{
                  onDeleteSkill,
                }}
              />
            )}
          </>
        ) : (
          <MinimumRecord
            skillSelected={skillsSelected}
            onChange={(e: any) => {
              setSkillsWithMinimumRecord(e)
              const skillsSelectedWithWeight = skillsSelected.map((skill: any) => {
                const skillWithWeight = e.find((skillWithMinRecord: any) => skillWithMinRecord.skill_id === skill.id)
                return {
                  ...skill,
                  weight: skillWithWeight.weight,
                }
              })
              setSkillsSelected(skillsSelectedWithWeight)
            }}
          />
        )}
        {
          <NextButtonContainer>
            <BackButton
              onClick={() => {
                if (isEditedMinimumRecord) {
                  setIsEditingMinimumRecord(false)
                } else {
                  if (stepThreeData.useBetesaTest) {
                    navigate(platformAdminRoutes.IdealProfileBetesa)
                  } else {
                    navigate(platformAdminRoutes.SelectTestsType)
                  }
                }
              }}
            />
            {skillsSelected.length > 0 && (
              <Button
                onClick={
                  !isEditedMinimumRecord ? () => handleDefineMinimumRecord() : () => handleNextStepWithMinimumRecord()
                }
                onDisabledClick={() => {
                  /* eslint-disable */
                  skillsSelected.length < 3
                    ? getWarning(`Debes seleccionar al menos ${MIN_SKILLS} competencias`)
                    : skillsSelected.length > MAX_SKILLS
                    ? getWarning(`No puedes seleccionar más de ${MAX_SKILLS} competencias`)
                    : _.some(skillsSelected, (skill) => skill.weight === undefined || skill.weight <= MIN_WEIGHT)
                    ? getWarning(`Debes asignar un puntaje mínimo de ${MIN_WEIGHT + 1} a cada competencia`)
                    : null
                }}
                type="submit"
                disabled={
                  isEditedMinimumRecord
                    ? hasInvalidSkills() || skillsSelected.length < 1
                      ? true
                      : false
                    : skillsSelected.length < MIN_SKILLS
                    ? true
                    : false
                }
              >
                {isEditedMinimumRecord ? 'Agregar usuarios' : 'Definir puntaje mínimo'}
              </Button>
            )}
          </NextButtonContainer>
        }
        {currentSkill && showModal && (
          <ModalSkills
            data={currentSkill}
            onClose={() => setShowModal(false)}
            visible={showModal}
            onViewNextSkill={handleViewNextSkill}
            onViewPreviousSkill={handleViewPreviousSkill}
            handleAddSkill={handleAddSkill}
            skillsSelected={skillsSelected}
          />
        )}
      </Container>
      <ModalLoader isLoading={loading} />
    </MainLayout>
  )
}

export default ProcessSkills
