import { useState } from 'react'
import {
  deleteTestsOfOneProcess,
  deleteUsersOfOneProcess,
} from 'services/common/process/createProcess/create-process-service'
import { useNotification } from 'lib/context/notification.context'

type ApiResponse = {
  status: string
  // Otras propiedades de la respuesta si las hay
}

type UseDeleteUsersInProcessResponse = {
  loadingDeleteUsersInProcess: boolean
  error: boolean
  success: boolean
  deleteUsersInProcess: (processId: number, usersIds: number[]) => Promise<void>
  deleteTestsInProcess: (processId: number) => Promise<void>
}

const useDeleteUsersInProcess = (): UseDeleteUsersInProcessResponse => {
  const { getSuccess } = useNotification()

  const [loadingDeleteUsersInProcess, setLoading] = useState(false)
  const [errorDeleteUsersInProcess, setError] = useState(false)
  const [successDeleteUsersInProcess, setSuccess] = useState(false)

  const deleteUsersInProcess = async (processId: number, usersIds: number[]) => {
    setLoading(true)
    setError(false)
    setSuccess(false)

    const response: ApiResponse = await deleteUsersOfOneProcess(processId, usersIds)

    if (response.status === 'OK') {
      setSuccess(true)
      getSuccess(` ${usersIds.length} usuarios eliminados correctamente`)
    }
  }

  const deleteTestsInProcess = async (processId: number) => {
    const response: ApiResponse = await deleteTestsOfOneProcess(processId)

    if (response.status === 'OK') {
      setSuccess(true)
    }
  }

  return {
    loadingDeleteUsersInProcess,
    error: errorDeleteUsersInProcess,
    success: successDeleteUsersInProcess,
    deleteUsersInProcess,
    deleteTestsInProcess,
  }
}

export default useDeleteUsersInProcess
