import { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import {
  Container,
  ContainerActions,
  EditContainer,
  BottomButtonsContainer,
  TextEdit,
  ResetButton,
  SearchButton,
  MagnifyingGlass,
} from './styled'
import Stepper from 'views/components/Stepper'
import { H2, H4, H5, P } from 'views/components/UI/Text'
import { Button, StrokedButton, BackButton } from 'views/components/UI/Buttons'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import Input from 'views/components/UI/TextInput'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import IconMagnifying from 'assets/icons/iconMagnifying.png'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { useNotification } from 'lib/context/notification.context'
import { headers, HEADERS_ADDED_USERS, steps, UserAdded } from './constants'
import DisclaimerModal from './disclaimer-modal'
import UploadModal from './upload-modal'

import { platformAdminRoutes } from 'router/routes'
import { Row } from 'views/components/UI/Row'
import {
  rootProcessSelector,
  setUserListAdded,
  setAcceptedTerms,
  setStepFiveTrigger,
  stepThreeSelector,
  stepsErrorsSelector,
  setUserListFailed,
  setLinkDownloadExcelUsersFailed,
} from 'ducks/createProcess' //setStepFive,
import { User } from './constants'
import { mergeUsers } from './main'
import { useSearchUsers } from 'hooks/users/useSearchUser/useSearchUser'
import useGetCompanyDetail from 'hooks/company/useGetCompanyDetail/useGetCompanyDetail'
import useGetUsersByProcess from 'hooks/process/useGetUsersByProcess/useGetUsersByProcess'
import { useGetCreditsAvailables } from 'hooks/inventory/useInventory'
import useDeleteUsersInProcess from 'hooks/process/useDeleteUsersInProcess/use-delete-users-in-process'
import { DEFAULT_LIMIT, USER_ROLE_ID } from 'constants/global.constants'
import searchUserByDocuments from 'hooks/users/useSearchUserByDocument/useSearchUserByDocument'
import _ from 'lodash'
import FinishProcessModal from './finish-process-modal'
import { updateAdmins, updateProcess } from 'services/common/process/createProcess/create-process-service'
import { useTranslation } from 'react-i18next'
import getCompanyInfo from 'services/companies/getCompanyInfo/getCompanyInfo'
import useCreateUsersBatch from 'hooks/users/useCreateUsersBatch/useCreaterUsersBatch'

const CreateUsers = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { getSuccess, getWarning, getError } = useNotification()
  const newUserId = location?.state?.newUserId || null
  const { search, results, paginationUsers, clearSearch } = useSearchUsers()
  const { results: listUsersByProcess } = useSearchUsers()
  const { createUsersBatch, resultsSucess, resultsFail, urlExcelUsersFailed } = useCreateUsersBatch()

  const { getOneCompany, companyInfo } = useGetCompanyDetail()
  const { deleteUsersInProcess } = useDeleteUsersInProcess()

  const stepOneData = useSelector(rootProcessSelector).process.stepOne
  const stepTwoData = useSelector(rootProcessSelector).process.stepTwo
  const stepThreeData = useSelector(stepThreeSelector)
  const stepFiveData = useSelector(rootProcessSelector).process.stepFive
  const stepFiveErrors = useSelector(stepsErrorsSelector)?.stepFive

  const { creditsAvailables: creditTestsAvailable, refreshCredits } = useGetCreditsAvailables(
    stepOneData.inventoryTypeId
  )
  const multiplierCredits = stepThreeData.useBetesaTest && stepThreeData.useSkillsTest ? 2 : 1

  const { isUsersLoaded, addedUsers: usersDB } = useGetUsersByProcess(stepOneData)

  const typesOfTest = `${stepThreeData?.useBetesaTest ? (stepThreeData.useSkillsTest ? 'Betesa - ' : 'Betesa ') : ''} ${
    stepThreeData?.useSkillsTest ? 'Competencias' : ''
  }`

  const userListAdded = stepFiveData?.userListAdded || []

  const [pageUsers, setPageUsers] = useState(1)
  const [pageUsersAdded, setPageUsersAdded] = useState(1)
  const [searchOnEnter, setSearchOnEnter] = useState(false)
  const [name, setName] = useState('')
  const [identification, setIdentification] = useState('')
  const [email, setEmail] = useState('')
  const [addedUsers, setAddedUsers] = useState<User[]>(userListAdded)

  const [openDisclaimerModal, setOpenDisclaimerModal] = useState(stepFiveData?.acceptedTerms || false)
  const [openUploadModal, setOpenUploadModal] = useState(false)
  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set())
  const [uploadedbyFile, setUploadedbyFile] = useState(false)
  const [newUserIdCreated, setNewUserIdCreated] = useState<number | null>(newUserId)
  const [openFinishModal, setOpenFinishModal] = useState(false)
  const [allowIntegration, setAllowIntegration] = useState(false)

  const isSendedProcess = addedUsers.some((user) => user.test_sent)

  useEffect(() => {
    if (resultsSucess.length > 0) {
      if (resultsFail.length > 0) {
        setTimeout(() => {
          const listNewUsers = resultsSucess.map((user: any) => {
            return {
              first_name: user.user.firstName,
              last_name: user.user.lastName,
              email: user.user.email,
              document: user.user.document,
              id: user.user.userId,
              userId: user.user.userId,
              test_types: typesOfTest,
            }
          })

          const usersMerged = [...listNewUsers]
            .map((user: any) => user.id)
            .filter((id: any) => typeof id === 'number') as number[]

          dispatch(setUserListAdded([...listNewUsers, ...addedUsers] as any))
          dispatch(
            setStepFiveTrigger.run({
              users: {
                process_id: stepOneData.processId,
                users_id: [...usersMerged, ...addedUsers.map((user) => user.id)],
              },
            } as any)
          )

          setAddedUsers([...listNewUsers, ...addedUsers] as any)
          getSuccess(`${resultsSucess.length} usuarios creados exitosamente`)
        }, 4000)
      } else {
        getSuccess(`${resultsSucess.length} usuarios creados exitosamente`)

        const listNewUsers = resultsSucess.map((user: any) => {
          return {
            first_name: user.user.firstName,
            last_name: user.user.lastName,
            email: user.user.email,
            document: user.user.document,
            id: user.user.userId,
            userId: user.user.userId,
            test_types: typesOfTest,
          }
        })

        const usersMerged = mergeUsers(listNewUsers, addedUsers)

        setAddedUsers(usersMerged as any)
        dispatch(setUserListAdded(usersMerged as any))

        const usersMergedIds = [...listNewUsers]
          .map((user: any) => user.id)
          .filter((id: any) => typeof id === 'number') as number[]

        dispatch(
          setStepFiveTrigger.run({
            users: {
              process_id: stepOneData.processId,
              users_id: usersMergedIds,
            },
          } as any)
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsSucess])

  useEffect(() => {
    if (resultsFail.length > 0) {
      dispatch(setUserListFailed(resultsFail))
      dispatch(setLinkDownloadExcelUsersFailed(urlExcelUsersFailed))

      const fetchUsersFailedWithId = async () => {
        try {
          const documentsUsersFailed = resultsFail.map((user: any) => user.user.document)

          const usersFound = (await searchUserByDocuments(documentsUsersFailed)) || []

          const usersFailedWithId: any = usersFound.map((user: any) => {
            return {
              ...user,
              firstName: user?.first_name,
              lastName: user?.last_name,
              id: user?.userId,
              test_types: typesOfTest,
            }
          })

          const listUniqUsers = _.uniqBy([...usersFailedWithId, ...addedUsers], 'document')

          setAddedUsers(listUniqUsers as UserAdded[])
          dispatch(setUserListAdded(listUniqUsers as UserAdded[]))
          dispatch(
            setStepFiveTrigger.run({
              users: {
                process_id: stepOneData.processId,
                users_id: usersFailedWithId.map((user: any) => user.id) as any,
              },
            } as any)
          )

          getWarning(
            `${resultsFail.length} usuarios no pudieron ser creados, por favor revisa los datos e intenta nuevamente`
          )
        } catch (error) {
          getWarning(`Error en la búsqueda de usuarios:`)
        }
      }

      fetchUsersFailedWithId()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsFail])

  const paginatedAddedUsers = useMemo(() => {
    if (addedUsers.length === 0) return []

    const uniqueUsers = _.uniqBy(addedUsers, 'document')
    const usersFormatted = uniqueUsers.map((user) => ({
      ...user,
      test_types:
        user.test_types && user.test_types.includes('Competencias')
          ? user.test_types.replace('Competencias', t('global.common.skillText'))
          : user.test_types,
    }))

    const sortedAddedUsers = [...usersFormatted].sort((a, b) => {
      if (a.test_sent === b.test_sent) return 0
      return a.test_sent ? 1 : -1
    })

    const firstIndex = (pageUsersAdded - 1) * DEFAULT_LIMIT
    const lastIndex = firstIndex + DEFAULT_LIMIT

    return sortedAddedUsers.slice(firstIndex, lastIndex)
  }, [addedUsers, pageUsersAdded, t])

  const handleAcceptDisclaimer = (isAcceptTerms: boolean) => {
    if (isAcceptTerms) {
      setOpenDisclaimerModal(isAcceptTerms)
      dispatch(setAcceptedTerms(isAcceptTerms))

      const dataToSaveProcess: any = {
        users: {
          process_id: stepOneData.processId,
          users_id: [],
        },
      }
      dispatch(setStepFiveTrigger.run(dataToSaveProcess))
    } else {
      getWarning('Debes aceptar los términos y condiciones para continuar')
    }
  }

  const handleAddUsersFromExcel = async (userList: User[]) => {
    if (
      creditTestsAvailable !== 'Ilimitado' &&
      (creditTestsAvailable <= 0 || userList.length * multiplierCredits > creditTestsAvailable)
    ) {
      getWarning(
        `El numero de usuarios y pruebas que intentas cargar (${
          userList.length * multiplierCredits
        }) supera el numero de pruebas disponibles (${creditTestsAvailable})`
      )
      return
    } else {
      dispatch(setUserListFailed([]))

      const addedUsersDocuments = new Set(addedUsers.map((user) => user.document))
      const addedUsersEmails = new Set(addedUsers.map((user) => user.email))

      const filteredUserList = userList.filter((user) => {
        return !addedUsersDocuments.has(user.document) && !addedUsersEmails.has(user.email)
      })

      const usersDocuments = filteredUserList.map((user) => user.document)

      const usersUploadedWithId = (await searchUserByDocuments(usersDocuments)) || []

      const usersUploadedDocuments = new Set(usersUploadedWithId?.map((user) => user.document))

      const usersToCreate = filteredUserList
        .filter((user) => !usersUploadedDocuments.has(user.document))
        .map((user: any) => {
          return {
            document: user.document,
            documentType: user.document_type,
            email: user.email,
            firstName: user.first_name,
            lastName: user.last_name,
            test_types: typesOfTest,
            id: user.document,
            active: user.active,
          }
        })

      await createUsersBatch(usersToCreate as any)

      const allAddedUsers = [...addedUsers, ...(usersUploadedWithId || [])].map((user: any) => {
        return {
          ...user,
          id: user.id || user.userId,
          test_types: typesOfTest,
        }
      })

      const dataToSaveProcess: any = {
        users: {
          process_id: stepOneData.processId,
          users_id: (usersUploadedWithId || []).map((user: any) => user.userId),
        },
      }

      const uniqueUsers = _.uniqBy(allAddedUsers, 'document')

      setAddedUsers(uniqueUsers as any)
      dispatch(setUserListAdded([...uniqueUsers]))
      setTimeout(() => {
        dispatch(setStepFiveTrigger.run(dataToSaveProcess))
      }, 500)
      setOpenUploadModal(false)
      setUploadedbyFile(true)

      const duplicatesCount = userList.length - allAddedUsers.length

      if (duplicatesCount > 0) {
        getWarning(t('global.notifications.duplicatedUserError', { duplicatesCount }))
      } else {
        getSuccess(t('global.notifications.addedMoreUsersSuccess'))
      }
    }

    refreshCredits()
  }

  const handleRemoveUser = (userDocument: string) => {
    let warningMessage = ''
    if (selectedItems.size > 0) {
      const updatedUsers = addedUsers.filter((user: any) => !selectedItems.has(user.userDocument))

      setAddedUsers(updatedUsers)
      setSelectedItems(new Set())
      dispatch(setUserListAdded([...updatedUsers]))
      const idUsersToDelete = addedUsers
        .filter((user: any) => selectedItems.has(user.userDocument))
        .map((user: any) => user.id)
      if (idUsersToDelete.length > 0) {
        deleteUsersInProcess(stepOneData.processId, idUsersToDelete)
      }

      warningMessage = 'Se eliminaron los usuarios seleccionados'
    } else {
      const updatedUsers = addedUsers.filter((user) => user.document !== userDocument)
      const idUserToDelete = addedUsers.find((user) => user.document === userDocument)?.id

      if (typeof idUserToDelete === 'number') {
        deleteUsersInProcess(stepOneData.processId, [idUserToDelete])
      }

      setAddedUsers(updatedUsers)
      dispatch(setUserListAdded([...updatedUsers]))
      warningMessage = t('global.notifications.userDeletedSuccess')
    }
    getWarning(warningMessage)
    refreshCredits()
  }

  const handleAddUser = async (userToAdd: UserAdded) => {
    if (creditTestsAvailable !== 'Ilimitado' && creditTestsAvailable <= 0) {
      getWarning(t('global.notifications.buyMoreCredits'))
      return
    } else {
      if (!addedUsers.find((user) => user.document === userToAdd.document)) {
        const userToAddData: User = {
          ...userToAdd,
          test_types: typesOfTest,
        }
        const updatedUsers = mergeUsers(addedUsers, [userToAddData])
        setAddedUsers(updatedUsers)

        const dataToSaveProcess: any = {
          users: {
            process_id: stepOneData.processId,
            users_id: [userToAddData.id],
          },
        }
        await dispatch(setStepFiveTrigger.run(dataToSaveProcess))

        if (stepFiveErrors?.error) {
          getWarning(stepFiveErrors?.message)
        } else {
          getSuccess(t('global.notifications.addUserSuccess'))
          dispatch(setUserListAdded([...updatedUsers]))
        }
      }
    }
    refreshCredits()
  }

  const handleNext = () => {
    if (addedUsers.length > 0) {
      navigate(platformAdminRoutes.Resume, { state: { data: addedUsers, uploadedbyFile } })
    } else {
      getWarning(t('global.notifications.addUsersBeforeContinue'))
    }
  }

  useEffect(() => {
    getOneCompany(stepOneData.companyId)
    refreshCredits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepOneData.companyId])

  useEffect(() => {
    if (stepOneData.processId) {
      if (isUsersLoaded) {
        const users = usersDB.map((user: any) => {
          return {
            id: user.userId || user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            company_name: user.company_name,
            document: user.document,
            email: user.email,
            test_types: typesOfTest,
            test_sent: user.test_sent,
            active: user.active,
            language: user.language,
          }
        })

        if (newUserIdCreated) {
          const allAddedUsers = mergeUsers(addedUsers, users, newUserId)
          const uniqAddedUsers = _.uniqBy(allAddedUsers, 'document')

          setAddedUsers(uniqAddedUsers)
          setNewUserIdCreated(null)
        } else {
          const allAddedUsers = mergeUsers(addedUsers, users)
          const uniqAddedUsers = _.uniqBy(allAddedUsers, 'document')
          setAddedUsers(uniqAddedUsers)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepOneData.processId, isUsersLoaded, typesOfTest])

  useEffect(() => {
    getCompanyInfo(stepOneData.companyId)
      .then((response) => {
        if (response.status === 'OK') {
          setAllowIntegration(response.data[0].allow_integration)
        }
      })
      .catch(() => {
        getError('Ha ocurrido un error al intentar obtener la información de la compañía')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepOneData.companyId])

  useEffect(() => {
    if (searchOnEnter) {
      search({ documentFilter: identification, nameFilter: name, emailFilter: email, role: [USER_ROLE_ID.user] })
      setSearchOnEnter(false)
    }
  }, [search, searchOnEnter, identification, name, email])

  useEffect(() => {
    if (listUsersByProcess.length > 0) {
      const users = listUsersByProcess.map((user: any) => {
        return {
          id: user.userId || user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          document: user.document,
          email: user.email,
          test_types: typesOfTest,
          test_sent: user.test_sent,
        }
      })

      const uniqueUsers = _.uniqBy(users, 'document')

      setAddedUsers(uniqueUsers || [])
    }
  }, [listUsersByProcess, typesOfTest])

  useEffect(() => {
    if (paginatedAddedUsers.length === 0 && pageUsersAdded > 1) {
      setPageUsersAdded(pageUsersAdded - 1)
      // uniq added users
      const uniqueUsers = _.uniqBy(addedUsers, 'document')
      setAddedUsers(uniqueUsers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageUsersAdded, paginatedAddedUsers])

  const handlePagination = useCallback(
    (page: number) => {
      setPageUsers(page)
      search({
        documentFilter: identification,
        nameFilter: name,
        emailFilter: email,
        page: page.toString(),
        role: [USER_ROLE_ID.user],
        processFinished: false,
      })
    },
    [email, identification, name, search]
  )

  const handleMultipleDeleteUsersAdded = () => {
    const newAddedUsers = addedUsers.filter((user: any) => !selectedItems.has(user.id))
    setAddedUsers(newAddedUsers)
    setSelectedItems(new Set())
    dispatch(setUserListAdded([...newAddedUsers]))
    getSuccess('Se eliminaron los usuarios seleccionados')
  }

  const resultsWithCompanyName = useMemo(() => {
    return results.map((user: any) => {
      return {
        ...user,
        company_name: companyInfo?.data?.name || '',
      }
    })
  }, [results, companyInfo])

  const handleResetSearch = () => {
    setName('')
    setIdentification('')
    setEmail('')
    setSearchOnEnter(false)
    setPageUsers(1)
    clearSearch()
  }

  const handleOmitStep = async () => {
    try {
      const finishProcess = await updateProcess(stepOneData?.processId, { finishProcess: true })
      const userId = stepOneData?.userId
      const admins = stepOneData?.admins.length > 0 ? stepOneData.admins : []
      const adminIdsToSave = userId !== undefined ? [userId, ...admins] : [...admins]
      const saveAdmins = await updateAdmins(stepOneData?.processId, adminIdsToSave)
      if (finishProcess.status === 'OK' && saveAdmins.status === 'OK') {
        getSuccess('Se ha guardado el proceso correctamente')
        navigate(platformAdminRoutes.ProcessesCreated)
      }
    } catch (error) {
      console.error('error on omit step', error)
      getError('Ha ocurrido un error al guardar el proceso')
    }
  }

  const ActionSearchDataComponent = (item: UserAdded) => {
    return (
      <ContainerActions>
        <EditContainer
          onClick={() => {
            handleAddUser(item)
          }}
        >
          <AddCircleOutlineIcon
            style={{ color: addedUsers.find((user) => user.document === item.document) ? 'gray' : '#2D749C' }}
          />
          <TextEdit style={{ color: addedUsers.find((user) => user.document === item.document) ? 'gray' : '#2D749C' }}>
            Agregar
          </TextEdit>
        </EditContainer>
      </ContainerActions>
    )
  }

  const ActionDeleteUserComponent = (item: UserAdded) => {
    const { document } = item

    const testSent = item?.test_sent || undefined

    return (
      <ContainerActions>
        {selectedItems.size === 0 && !testSent ? (
          <EditContainer
            onClick={() => {
              handleRemoveUser(document)
            }}
          >
            <DeleteOutlineIcon style={{ color: 'red' }} />
            <TextEdit style={{ color: 'red' }}>Eliminar</TextEdit>
          </EditContainer>
        ) : null}
      </ContainerActions>
    )
  }

  return (
    <MainLayout>
      <Container>
        <H2 margin="0px 0px 20px 0px">Agregar usuarios</H2>
        <Stepper activeStep={4} steps={steps} />
        <P>Selecciona cómo deseas agregar usuarios a este proyecto (crear nuevo, agregar existente)</P>
        <Row alignItems="center" justifyContent="space-between">
          <Row gap="16px" width="70%" justifyContent="flex-start">
            <H5 color="gray">Cantidad de pruebas disponibles: </H5>
            <H4>{creditTestsAvailable > '9999999' ? 'Ilimitado' : creditTestsAvailable}</H4>
          </Row>
          <Row gap="20px">
            {stepOneData?.profileId && allowIntegration && (
              <StrokedButton
                onClick={() => {
                  setOpenFinishModal(true)
                }}
                style={{ marginBottom: '0' }}
              >
                Saltar este paso
              </StrokedButton>
            )}
            <StrokedButton
              onClick={() => {
                setOpenUploadModal(true)
              }}
              style={{ marginBottom: '0' }}
            >
              Importar desde excel
            </StrokedButton>
            <Row
              gap="10px"
              alignItems="center"
              justifyContent="flex-end"
              width="fit-content"
              style={{
                color: '#2D749C',
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <AddCircleOutlineIcon />
              <span
                style={{ color: '#2D749C', fontWeight: 'bold', cursor: 'pointer', margin: '10px 0px' }}
                onClick={() => {
                  if (creditTestsAvailable !== 'Ilimitado' && creditTestsAvailable <= 0) {
                    getWarning('Debes comprar más créditos para agregar más usuarios')
                  } else {
                    navigate(platformAdminRoutes.NewUser)
                  }
                }}
              >
                Crear nuevo usuario
              </span>
            </Row>
          </Row>
        </Row>
        <Row gap="20px" justifyContent="center" alignItems="center">
          <P style={{ width: '200px' }}>Buscar existente:</P>
          <Input
            name="identification"
            type="text"
            label="Número de identificación"
            value={identification}
            onChange={(e) => setIdentification(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setSearchOnEnter(true)
              }
            }}
          />
          <Input
            name="email"
            type="text"
            label="Correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setSearchOnEnter(true)
              }
            }}
          />
          <Input
            name="name"
            type="text"
            label="Nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setSearchOnEnter(true)
              }
            }}
          />
          <ResetButton onClick={handleResetSearch}>
            <HighlightOffIcon />
          </ResetButton>
          <SearchButton
            onClick={() =>
              search({
                documentFilter: identification,
                nameFilter: name,
                emailFilter: email,
                role: [USER_ROLE_ID.user],
              })
            }
            style={{ fontSize: 14 }}
          >
            <MagnifyingGlass src={IconMagnifying} />
          </SearchButton>
        </Row>
        {resultsWithCompanyName.length > 0 ? (
          <>
            <h5 style={{ margin: 5 }}>Coincidencias</h5>
            <BluesiteTableComponent
              ActionComponent={ActionSearchDataComponent}
              data={resultsWithCompanyName}
              headers={headers}
              searchBar={false}
              searchLabel=""
              handlePagination={handlePagination}
              totalPages={paginationUsers.total_pages}
              currentPage={pageUsers}
            />
          </>
        ) : null}
        {addedUsers.length ? (
          <>
            <Row justifyContent="space-between">
              <h5 style={{ margin: 5 }}>Lista de usuarios agregados</h5>
              {selectedItems.size > 0 ? (
                <StrokedButton style={{ marginBottom: '-60px' }} onClick={handleMultipleDeleteUsersAdded}>
                  Eliminar seleccionados
                </StrokedButton>
              ) : null}
            </Row>
            <BluesiteTableComponent
              ActionComponent={ActionDeleteUserComponent}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              checks={false}
              data={paginatedAddedUsers}
              headers={HEADERS_ADDED_USERS}
              searchBar={false}
              searchLabel=""
              currentPage={pageUsersAdded}
              totalPages={Math.ceil(addedUsers.length / DEFAULT_LIMIT)}
              handlePagination={setPageUsersAdded}
              showNumbers={true}
            />
            <BottomButtonsContainer>
              <BackButton
                onClick={() => {
                  if (isSendedProcess) {
                    navigate(-1)
                  } else {
                    if (stepTwoData.isRoleFamilySelected) {
                      navigate(platformAdminRoutes.PreconfigProcess)
                    } else {
                      if (stepThreeData.useSkillsTest && stepThreeData.useBetesaTest) {
                        navigate(platformAdminRoutes.ProcessSkills)
                      } else if (stepThreeData.useSkillsTest && !stepThreeData.useBetesaTest) {
                        navigate(platformAdminRoutes.ProcessSkills)
                      } else if (!stepThreeData.useSkillsTest && stepThreeData.useBetesaTest) {
                        navigate(platformAdminRoutes.IdealProfileBetesa)
                      }
                    }
                  }
                }}
              />
              <Button onClick={handleNext} type="submit">
                {t('global.common.nextButtonText')}
              </Button>
            </BottomButtonsContainer>
          </>
        ) : (
          <BackButton
            style={{ marginTop: '100px' }}
            onClick={() => {
              if (isSendedProcess) {
                navigate(-1)
              } else {
                if (stepTwoData.isRoleFamilySelected) {
                  navigate(platformAdminRoutes.PreconfigProcess, { state: { goBack: true } })
                } else {
                  if (stepThreeData.useSkillsTest && stepThreeData.useBetesaTest) {
                    navigate(platformAdminRoutes.ProcessSkills)
                  } else if (stepThreeData.useSkillsTest && !stepThreeData.useBetesaTest) {
                    navigate(platformAdminRoutes.ProcessSkills)
                  } else if (!stepThreeData.useSkillsTest && stepThreeData.useBetesaTest) {
                    navigate(platformAdminRoutes.IdealProfileBetesa)
                  }
                }
              }
            }}
          />
        )}
      </Container>
      <DisclaimerModal
        accepTerms={openDisclaimerModal}
        setAccepTerms={(e) => {
          handleAcceptDisclaimer(e)
        }}
      />
      <UploadModal
        openModal={openUploadModal}
        setOpenModal={setOpenUploadModal}
        setAddedUsers={handleAddUsersFromExcel}
        setUploadedbyFile={setUploadedbyFile}
      />
      <FinishProcessModal
        showConfirmModal={openFinishModal}
        setShowConfirmModal={setOpenFinishModal}
        onConfirm={handleOmitStep}
      />
    </MainLayout>
  )
}

export default CreateUsers
