import styled from 'styled-components'

interface IDividerProps {
  margin?: string
}

export const Divider = styled.div<IDividerProps>`
  width: 100%;
  height: 1px;
  background-color: #1f2124;
  margin: ${(props) => (props.margin ? props.margin : '0px')};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  padding: 3.5%;
  margin-bottom: 40px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
  }
`

export const StepsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 3%;
  padding: 3% 0%;
`

//Grids

// grid container
export const GridContainer = styled.div`
  width: 100%;
  padding: 0% 0%;
  margin-top: 20px;
`

// grid with 2 columns, proportion 5:5
export const GridMinimumRecord = styled.div`
  display: grid;
  grid-template-columns: 6fr 6fr;
  width: 100%;
  padding: 0% 0%;
`

interface IGridItemProps {
  flexDirection?: string
  margin?: string
  height?: string
}

export const GridItem = styled.div<IGridItemProps>`
  display: flex;
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'column')};
  width: 100%;
  height: ${(props) => (props.height ? props.height : '60px')};
  padding: 0% 0%;
  margin: ${(props) => (props.margin ? props.margin : '0px')};
`

export const TitleGrid = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #1f2124;
  font-family: 'Public Sans', sans-serif;
  font-weight: 600;
  margin: 10px 0px 1px 0px;
`

interface IRowProps {
  width?: string
  justify?: string
  height?: string
  margin?: string
}

export const Row = styled.div<IRowProps>`
  display: flex;
  flex-direction: row;
  width: ${(props) => (props.width ? props.width : '100%')};
  justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  align-items: center;
  margin: ${(props) => (props.margin ? props.margin : '0px')};
`

// next button

export const NextButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  justify-content: space-between;
  align-items: flex-end;
`

export const MinimumRecordContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`

export const ModalCloseButtonContainer = styled.div`
  display: flex;
  width: 98%;
  justify-content: flex-end;
  margin-bottom: 10px;
  z-index: 2;
`

export const DescriptionText = styled.p`
  font-family: 'Public Sans', sans-serif;
  color: #1f2124;
`

export const NavigationText = styled.p`
  font-family: 'Public Sans', sans-serif;
  color: #2d749c;
  font-size: 16px;
  font-weight: 600;
  margin: 2px 6px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export const ModalBottomNavigationContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
  z-index: 2;
`

export const ContainerWithNumbers = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`
