/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useCallback, useMemo, useRef, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { MainLayout } from 'views/layouts'
import {
  Container,
  ContainerActions,
  EditContainer,
  HorizontalDivider,
  SectionOne,
  SectionOneItem,
  SectionOneItemDescription,
  SectionOneItemNumber,
  TextEdit,
  TextAreaDiv,
  SearchButton,
  MagnifyingGlass,
  ButtonsContainer,
  ButtonSave,
  ButtonCancel,
  ButtonEdit,
} from './styled'
import { H2, P } from 'views/components/UI/Text'
import { StrokedButton, BackButton, Button } from 'views/components/UI/Buttons'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import Input from 'views/components/UI/TextInput'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import IconMagnifying from 'assets/icons/iconMagnifying.png'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { HEADERS_ADDED_USERS_EDITABLE, UserAdded } from '../Create/Users/constants'
import { useNotification } from 'lib/context/notification.context'
import { platformAdminRoutes } from 'router/routes'
import { Row } from 'views/components/UI/Row'
import {
  stepOneSelector,
  stepThreeSelector,
  stepFiveSelector,
  setUserListAdded,
  setUserListFailed,
  setStepFiveTrigger,
  setStepFiveEmailTemplate,
  getEmailTemplateStepFive,
  setLinkDownloadExcelUsersFailed,
  getTestTypes,
} from 'ducks/createProcess'
import { companyIdSelector } from 'ducks/user'
import { useGetInventoryById } from 'hooks/inventory/useInventory'
import useDeleteUsersInProcess from 'hooks/process/useDeleteUsersInProcess/use-delete-users-in-process'
import useGetCompanyDetail from 'hooks/company/useGetCompanyDetail/useGetCompanyDetail'
import { useSearchUsers } from 'hooks/users/useSearchUser/useSearchUser'
import useCreateUsersBatch from 'hooks/users/useCreateUsersBatch/useCreaterUsersBatch'
import useGetUsersByProcess from 'hooks/process/useGetUsersByProcess/useGetUsersByProcess'
import searchUserByDocuments from 'hooks/users/useSearchUserByDocument/useSearchUserByDocument'
import { useSendExamToUsers } from 'hooks/process/useSendExamToUsers/useSendExamToUsers'
import { DEFAULT_LIMIT } from 'constants/global.constants'
import { getCountQuestionnaires, deleteUsersDuplicate } from './main'
import _ from 'lodash'
import { mergeUsers } from '../Create/Users/main'
import { getLogoUrl } from 'views/screens/Platform-administrator/constants/fetch_process'
import { useTranslation } from 'react-i18next'
import updateProcessUser from 'services/common/users/update-process-user'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { updateLanguage } from '../../services'

interface SearchParams {
  name?: string
  email?: string
  identification?: string
}

interface UserLanguages {
  [userId: number]: string
}

function UsersCreateProcess() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  dayjs.extend(utc)
  const { getSuccess, getWarning, getError } = useNotification()
  const { getOneCompany, companyInfo } = useGetCompanyDetail()
  const { loading, createUsersBatch, resultsSucess, resultsFail, urlExcelUsersFailed } = useCreateUsersBatch()
  const { deleteUsersInProcess } = useDeleteUsersInProcess()

  const { search, results, clearSearch } = useSearchUsers()
  const { handleSendExamToUsers, loadingSendExamToUser, setLoading } = useSendExamToUsers()

  const companyId = useSelector(companyIdSelector)
  const stepOneData = useSelector(stepOneSelector)
  const stepFiveData = useSelector(stepFiveSelector)
  const newUserId = location?.state?.newUserId || null

  const { isUsersLoaded, addedUsers: usersDB } = useGetUsersByProcess(stepOneData)
  const [newUserIdCreated, setNewUserIdCreated] = useState<number | null>(newUserId)

  const templateEmailStepFive = useSelector(getEmailTemplateStepFive)
  const testTypes = useSelector(getTestTypes)
  const [refreshInventory, setRefreshInventory] = useState(false)
  const { inventoryData } = useGetInventoryById(stepOneData.inventoryTypeId, refreshInventory)
  const { inventoryUniqName, processId } = stepOneData
  const stepThreeData = useSelector(stepThreeSelector)
  const usersAddedOnPrevStep = location.state?.data ? deleteUsersDuplicate(location.state?.data) : []
  const amountInventory = inventoryData ? inventoryData.credits - inventoryData.usedCredits : 0

  const [searchOnEnter, setSearchOnEnter] = useState(false)

  const [executedCreateUser, setExecuteCreateUser] = useState(false)
  const [pageUsersAdded, setPageUsersAdded] = useState(1)
  const [pageResults, setPageResults] = useState(1)

  const [showModal, setShowModal] = useState(false)

  const [name, setName] = useState('')
  const [identification, setIdentification] = useState('')
  const [email, setEmail] = useState('')

  const uploadType = location.state?.uploadedbyFile
  const tryFlag = location.state?.tryAgain
  const [addedUsers, setAddedUsers] = useState<UserAdded[]>([])
  const [usersFailed] = useState<UserAdded[]>([])
  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set())
  const [searchResults, setSearchResults] = useState(addedUsers)
  const [totalPages, setTotalPages] = useState(0)
  const [tableLoading, setTableLoading] = useState(false)
  const [userLanguages, setUserLanguages] = useState<UserLanguages>({})

  const paginatedAddedUsers = useMemo(() => {
    if (addedUsers.length === 0) return []
    setTotalPages(Math.ceil(searchResults.length / 10))
    const firstIndex = (pageUsersAdded - 1) * DEFAULT_LIMIT
    const lastIndex = firstIndex + DEFAULT_LIMIT
    const userMerged = searchResults.slice(firstIndex, lastIndex)
    const usersFormatted = userMerged.map((user) => ({
      ...user,
      test_types:
        user.test_types && user.test_types.includes('Competencias')
          ? user.test_types.replace('Competencias', t('global.common.skillText'))
          : user.test_types,
    }))
    return _.uniqBy(usersFormatted, 'document')
  }, [addedUsers, pageUsersAdded, searchResults, t])

  const searchUsers = (searchParams: SearchParams) => {
    setPageUsersAdded(1)
    return addedUsers.filter((user) => {
      const fullName = `${user.first_name} ${user.last_name}`.toLowerCase()
      const searchName = (searchParams.name || '').toLowerCase().replace(/\s/g, '')

      const userEmail = (user.email || '').toLowerCase().replace(/\s/g, '')
      const searchMail = (searchParams.email || '').toLowerCase().replace(/\s/g, '')

      const userIdentification = (user.document || '').toLowerCase().replace(/\s/g, '')
      const searchIdentification = (searchParams.identification || '').toLowerCase().replace(/\s/g, '')

      const typesOfTest = (user.test_types || '').toLowerCase().replace(/\s/g, '')

      return (
        (fullName.includes(searchName) || typesOfTest.includes(searchName)) &&
        userEmail.includes(searchMail) &&
        userIdentification.includes(searchIdentification)
      )
    })
  }

  useEffect(() => {
    if (name || email || identification) {
      const resultsOfSearch = searchUsers({ name, email, identification })
      setSearchResults(resultsOfSearch)
    } else {
      setSearchResults(addedUsers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, email, identification, addedUsers])

  const handleCreateUsers = useCallback(async () => {
    if (executedCreateUser) return

    setExecuteCreateUser(true)

    const listUsersWithoutAccount = usersAddedOnPrevStep
      .filter((user: UserAdded) => typeof user.id !== 'number')
      .map((user: UserAdded) => {
        return {
          ...user,
          firstName: user.first_name,
          lastName: user.last_name,
        }
      })

    const usersPrevCreated = usersAddedOnPrevStep
      .filter((user: UserAdded) => typeof user.id === 'number')
      .map((user: UserAdded) => {
        return {
          ...user,
          firstName: user.first_name,
          lastName: user.last_name,
        }
      })

    if (usersPrevCreated.length) {
      setAddedUsers([...usersPrevCreated] as any)
    } else {
      usersPrevCreated.map((user: any) => {
        return {
          ...user,
          firstName: user.first_name,
          lastName: user.last_name,
        }
      })
      if (stepFiveData.userListAdded && newUserIdCreated) {
        const usersFormated = stepFiveData.userListAdded?.map((user: any) => {
          return {
            ...user,
            firstName: user.first_name,
            lastName: user.last_name,
          }
        })

        const mergedUsers = mergeUsers(usersFormated, usersPrevCreated, newUserId)

        const uniqUsers = _.uniqBy(mergedUsers, 'document')
        setAddedUsers([...uniqUsers] as any)
        setNewUserIdCreated(null)
      } else {
        setAddedUsers([...usersPrevCreated] as any)
      }
    }

    const dataToSaveProcess: any = {
      users: {
        process_id: stepOneData.processId,
        users_id: usersPrevCreated.map((user: UserAdded) => user.id),
      },
    }

    // dispatch(setStepFiveTrigger.run(dataToSaveProcess))

    if (
      executedCreateUser === false &&
      listUsersWithoutAccount.length > 0 &&
      stepFiveData.userListFailed?.length === 0
    ) {
      setExecuteCreateUser(true)
      createUsersBatch(listUsersWithoutAccount as any)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepFiveData.userListAdded])

  const hasRun = useRef(false)

  useEffect(() => {
    getOneCompany(companyId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId])

  useEffect(() => {
    if (companyInfo && !templateEmailStepFive) {
      const newDateTemplate = dayjs.utc().add(7, 'day').format('DD/MM/YYYY')
      dispatch(
        setStepFiveEmailTemplate({
          textOne: `Como parte del proceso que estás realizando para ${companyInfo?.data.name}, te invitamos a leer detenidamente la información contenida en este correo.`,
          textTwo: searchResults.some((item) => item.active === true)
            ? `Tu cuenta en Bluesite está activa. Ingresa con tu correo y la clave que creaste cuando ingresaste por
          primera vez. Si no la recuerdas, puedes hacer click en "Olvidé mi Contraseña" para restaurarla`
            : `Para ingresar a la plataforma Bluesite y diligenciar los cuestionarios asignados, que nos ayudarán a conocer mejor tu perfil, completa los siguientes pasos:`,
          textThree: `Por favor, ten en cuenta lo siguiente:`,
          textFour: `Ten presente que, una vez finalices el cuestionario, tus resultados serán compartidos con el equipo de ${companyInfo?.data.name}, quienes los revisarán y te contactarán para indicar los siguientes pasos en tu proceso.`,
          textThreeOptions: searchResults.some((item) => item.active === true)
            ? [
                `La fecha límite para que llenes los cuestionarios asignados es el ${newDateTemplate} `,
                'Debes disponer de al menos 1 hora y 30 minutos para realizar el proceso.',
                'Elige un lugar tranquilo que te brinde privacidad, silencio y buena iluminación.',
              ]
            : [
                'Debes disponer de alrededor de 40 minutos por prueba asignada.',
                'Elige un lugar tranquilo que te brinde privacidad, silencio y buena iluminación.',
              ],
          date: dayjs.utc().add(7, 'day').format('DD/MM/YYYY'),
          active: searchResults.some((item) => item.active === true),
        })
      )
    }
  }, [companyInfo, dispatch, searchResults, templateEmailStepFive])
  // start
  useEffect(() => {
    if (!hasRun.current && !loading && !isUsersLoaded) {
      handleCreateUsers()
      hasRun.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isUsersLoaded, usersDB])

  /* useEffect(() => {
    if (resultsFail.length > 0) {
      dispatch(setUserListFailed(resultsFail))
      dispatch(setLinkDownloadExcelUsersFailed(urlExcelUsersFailed))

      const fetchUsersFailedWithId = async () => {
        try {
          const documentsUsersFailed = resultsFail.map((user: any) => user.user.document)

          const usersFound = (await searchUserByDocuments(documentsUsersFailed)) || []

          const usersFailedWithId: any = usersFound.map((user: any) => {
            return {
              ...user,
              firstName: user?.first_name,
              lastName: user?.last_name,
              id: user?.userId,
              test_types: testTypes,
            }
          })

          const listUniqUsers = _.uniqBy([...usersFailedWithId, ...addedUsers], 'document')

          setAddedUsers(listUniqUsers as UserAdded[])
          dispatch(setUserListAdded(listUniqUsers as UserAdded[]))
          dispatch(
            setStepFiveTrigger.run({
              users: {
                process_id: stepOneData.processId,
                users_id: listUniqUsers.map((user: any) => user.id) as any,
              },
            } as any)
          )

          getWarning(
            `${resultsFail.length} usuarios no pudieron ser creados, por favor revisa los datos e intenta nuevamente`
          )
        } catch (error) {
          getWarning(`Error en la búsqueda de usuarios:`)
        }
      }

      fetchUsersFailedWithId()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsFail]) */

  /* useEffect(() => {
    if (resultsSucess.length > 0) {
      if (resultsFail.length > 0) {
        setTimeout(() => {
          const listNewUsers = resultsSucess.map((user: any) => {
            return {
              first_name: user.user.firstName,
              last_name: user.user.lastName,
              email: user.user.email,
              document: user.user.document,
              id: user.user.userId,
              userId: user.user.userId,
              test_types: testTypes,
            }
          })

          const usersMerged = [...listNewUsers]
            .map((user: any) => user.id)
            .filter((id: any) => typeof id === 'number') as number[]

          dispatch(setUserListAdded([...listNewUsers, ...addedUsers] as any))
          dispatch(
            setStepFiveTrigger.run({
              users: {
                process_id: stepOneData.processId,
                users_id: usersMerged,
              },
            } as any)
          )

          setAddedUsers([...listNewUsers, ...addedUsers] as any)
          getSuccess(`${resultsSucess.length} usuarios creados exitosamente`)
        }, 4000)
      } else {
        getSuccess(`${resultsSucess.length} usuarios creados exitosamente`)

        const listNewUsers = resultsSucess.map((user: any) => {
          return {
            first_name: user.user.firstName,
            last_name: user.user.lastName,
            email: user.user.email,
            document: user.user.document,
            id: user.user.userId,
            userId: user.user.userId,
            test_types: testTypes,
          }
        })

        const usersMerged = mergeUsers(listNewUsers, addedUsers)

        setAddedUsers(usersMerged as any)
        dispatch(setUserListAdded(usersMerged as any))

        const usersMergedIds = [...listNewUsers]
          .map((user: any) => user.id)
          .filter((id: any) => typeof id === 'number') as number[]

        dispatch(
          setStepFiveTrigger.run({
            users: {
              process_id: stepOneData.processId,
              users_id: usersMergedIds,
            },
          } as any)
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsSucess]) */

  useEffect(() => {
    if (searchOnEnter) {
      search({ documentFilter: identification, nameFilter: name, emailFilter: email, processId })
      setSearchOnEnter(false)
    }
  }, [search, searchOnEnter, identification, name, email, processId])

  useEffect(() => {
    if (hasRun.current && isUsersLoaded && addedUsers.length === 0) {
      setAddedUsers(usersDB as any)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUsersLoaded, usersDB])

  const handlePagination = useCallback((page: number) => {
    setPageUsersAdded(page)
  }, [])

  const handlePaginationResults = useCallback(
    (page: number) => {
      setPageResults(page)
      search({ documentFilter: identification, nameFilter: name, emailFilter: email, page: page.toString(), processId })
    },
    [email, identification, name, search, processId]
  )

  const handleRemoveUser = (userDocument: string) => {
    let warningMessage = ''
    if (selectedItems.size > 0) {
      const updatedUsers = addedUsers.filter((user: any) => user.document !== userDocument)
      setAddedUsers(updatedUsers)
      setSelectedItems(new Set())
      dispatch(setUserListAdded([...updatedUsers]))

      const idUsersToDelete = addedUsers
        .filter((user: any) => selectedItems.has(user.userDocument))
        .map((user: any) => user.id)
      if (idUsersToDelete.length > 0) {
        deleteUsersInProcess(stepOneData.processId, idUsersToDelete)
      }

      if (updatedUsers.length === 0) {
        getWarning('No hay usuarios agregados, por favor agrega usuarios para continuar')
        setTimeout(() => {
          navigate(platformAdminRoutes.CreateUsers)
        }, 2000)
      }
      warningMessage = 'Se eliminaron los usuarios seleccionados'
    } else {
      const updatedUsers = addedUsers.filter((user: any) => user.document !== userDocument)
      const idUserToDelete = addedUsers.find((user: any) => user.document === userDocument)?.id

      if (updatedUsers.length % 10 === 0 && pageUsersAdded > 1) {
        setPageUsersAdded((prev) => prev - 1)
      }

      if (idUserToDelete) {
        deleteUsersInProcess(stepOneData.processId, [idUserToDelete])
      }

      setAddedUsers(updatedUsers)
      dispatch(setUserListAdded([...updatedUsers]))

      if (updatedUsers.length === 0) {
        getWarning('No hay usuarios agregados, por favor agrega usuarios para continuar')
        setTimeout(() => {
          navigate(platformAdminRoutes.CreateUsers)
        }, 2000)
      }
      warningMessage = 'Se eliminó el usuario seleccionado'
    }
    getWarning(warningMessage)
    setTimeout(() => {
      setRefreshInventory(!refreshInventory)
    }, 500)
  }

  const handleSendToUsers = async () => {
    setLoading(true)
    const usersIds = addedUsers.map((user) => user.id).filter((userId: any) => typeof userId === 'number') as number[]

    const changedMailUsersActive = _.uniqBy(
      addedUsers.filter((user) => typeof user.id === 'number' && user.active === true && user.emailEdit === true),
      'id'
    ).map((user) => user.id) as number[]

    const changedMailUsersInactive = _.uniqBy(
      addedUsers.filter(
        (user) => typeof user.id === 'number' && (user.active === false || !user.active) && user.emailEdit === true
      ),
      'id'
    ).map((user) => user.id) as number[]

    const activeUsersIds = _.uniqBy(
      addedUsers.filter((user) => typeof user.id === 'number' && user.active === true && !user.emailEdit),
      'id'
    ).map((user) => user.id) as number[]

    const inactiveUsersIds = _.uniqBy(
      addedUsers.filter(
        (user) => typeof user.id === 'number' && (user.active === false || !user.active) && !user.emailEdit
      ),
      'id'
    ).map((user) => user.id) as number[]

    const dataToSaveUsersStepFive: any = {
      users: {
        process_id: stepOneData.processId,
        users_id: usersIds,
      },
    }

    setShowModal(true)
    // dispatch(setStepFiveTrigger.run(dataToSaveUsersStepFive))
    const logoData = await getLogoUrl(processId)
    const newDateTemplate = dayjs.utc().add(7, 'day').format('DD/MM/YYYY')
    const emailTemplateStepFiveOne = {
      textOne: templateEmailStepFive?.textOne,
      textTwo: `Tu cuenta en Bluesite está activa. Ingresa con tu correo y la clave que creaste cuando ingresaste por
      primera vez. Si no la recuerdas, puedes hacer click en "Olvidé mi Contraseña" para restaurarla`,
      textThree: templateEmailStepFive?.textThree,
      textFour: templateEmailStepFive?.textFour,
      textThreeOptions: [
        `La fecha límite para que llenes los cuestionarios asignados es el ${
          templateEmailStepFive ? templateEmailStepFive.date : newDateTemplate
        }`,
        'Debes disponer de al menos 1 hora y 30 minutos para realizar el proceso.',
        'Elige un lugar tranquilo que te brinde privacidad, silencio y buena iluminación.',
      ],
      date: templateEmailStepFive.date || dayjs.utc().add(7, 'day').format('DD/MM/YYYY'),
      active: true,
      logo: logoData.cobranding.logo,
    }

    const emailTemplateStepFiveTwo = {
      textOne: templateEmailStepFive?.textOne,
      textTwo: `Para ingresar a la plataforma Bluesite y diligenciar los cuestionarios asignados, que nos ayudarán a conocer mejor tu perfil, completa los siguientes pasos:`,
      textThree: templateEmailStepFive?.textThree,
      textFour: templateEmailStepFive?.textFour,
      textThreeOptions: [
        'Debes disponer de alrededor de 40 minutos por prueba asignada.',
        'Elige un lugar tranquilo que te brinde privacidad, silencio y buena iluminación.',
      ],
      date: templateEmailStepFive.date || dayjs.utc().add(7, 'day').format('DD/MM/YYYY'),
      active: false,
      logo: logoData.cobranding.logo,
    }

    let totalCalls = 0
    let completedCalls = 0

    if (changedMailUsersActive.length > 0) totalCalls++
    if (changedMailUsersInactive.length > 0) totalCalls++
    if (activeUsersIds.length > 0) totalCalls++
    if (inactiveUsersIds.length > 0) totalCalls++

    if (activeUsersIds.length > 0) {
      const usersData = activeUsersIds.map((userId) => {
        const user = addedUsers.find((item) => item.id === userId)
        const language = user && user.language
        return {
          user_id: userId,
          language: language || userLanguages[userId] || 'es',
        }
      })
      const dataToSend = {
        process_id: processId,
        users_data: usersData,
        company_id: companyId,
        type: 'test_send',
        email_template: emailTemplateStepFiveOne,
      }

      setTimeout(() => {
        completedCalls++
        handleSendExamToUsers(dataToSend, totalCalls, completedCalls)
      }, 3500)
    }

    if (inactiveUsersIds.length > 0) {
      const usersData = inactiveUsersIds.map((userId) => {
        const user = addedUsers.find((item) => item.id === userId)
        const language = user && user.language
        return {
          user_id: userId,
          language: language || userLanguages[userId] || 'es',
        }
      })
      const dataToSendInactive = {
        process_id: processId,
        users_data: usersData,
        company_id: companyId,
        type: 'test_send',
        email_template: emailTemplateStepFiveTwo,
      }

      setTimeout(() => {
        completedCalls++
        handleSendExamToUsers(dataToSendInactive, totalCalls, completedCalls)
      }, 4000)
    }

    if (changedMailUsersActive.length > 0) {
      const usersData = changedMailUsersActive.map((userId) => {
        const user = addedUsers.find((item) => item.id === userId)
        const language = user && user.language
        return {
          user_id: userId,
          language: language || userLanguages[userId] || 'es',
        }
      })
      const dataToSend = {
        process_id: processId,
        users_data: usersData,
        company_id: companyId,
        type: 'test_resend',
        count: 0,
        email_template: emailTemplateStepFiveOne,
      }

      setTimeout(() => {
        completedCalls++
        handleSendExamToUsers(dataToSend, totalCalls, completedCalls)
      }, 4500)
    }

    if (changedMailUsersInactive.length > 0) {
      const usersData = changedMailUsersInactive.map((userId) => {
        const user = addedUsers.find((item) => item.id === userId)
        const language = user && user.language
        return {
          user_id: userId,
          language: language || userLanguages[userId] || 'es',
        }
      })
      const dataToSend = {
        process_id: processId,
        users_data: usersData,
        company_id: companyId,
        type: 'test_send_email_change',
        count: 0,
        email_template: emailTemplateStepFiveTwo,
      }

      setTimeout(() => {
        completedCalls++
        handleSendExamToUsers(dataToSend, totalCalls, completedCalls)
      }, 5000)
    }
    setShowModal(false)
  }

  const ActionDeleteUser = (item: any) => {
    const { document, test_sent: testSend } = item
    return (
      <ContainerActions>
        {!testSend && (
          <EditContainer
            onClick={() => {
              handleRemoveUser(document)
            }}
          >
            <DeleteOutlineIcon style={{ color: 'red' }} />
            <TextEdit style={{ color: 'red' }}>Eliminar</TextEdit>
          </EditContainer>
        )}
      </ContainerActions>
    )
  }

  const handleSave = async (rowIndex: number, editingRow: any) => {
    try {
      setTableLoading(true)

      const originalUser = addedUsers.find((user) => user.id === editingRow.id)
      let updatedEditingRow

      if (originalUser && originalUser.email !== editingRow.email && editingRow.test_sent) {
        updatedEditingRow = { ...editingRow, emailEdit: true }
      }

      const response = await updateProcessUser({
        userId: editingRow.id,
        firstName: editingRow.first_name,
        lastName: editingRow.last_name,
        email: editingRow.email,
        document: editingRow.document,
      })
      if (response.status === 'OK') {
        const { edit, ...rest } = updatedEditingRow ? updatedEditingRow : editingRow
        setAddedUsers((prev) => {
          const userIndex = prev.findIndex((user) => user.id === rest.id)
          if (userIndex !== -1) {
            const newUsers = [...prev]
            const currentLanguage = prev[userIndex].language
            const updatedUser = { ...rest, language: currentLanguage }
            newUsers[userIndex] = updatedUser
            dispatch(setUserListAdded(newUsers))
            return newUsers
          }
          return prev
        })
        getSuccess(t('global.notifications.updateUserSuccess'))
      }
      if (response.status === 'error' && response.message === 'Email or document already exists') {
        getWarning(t('global.notifications.updateUserError'))
      }
      setTableLoading(false)
    } catch (error) {
      setTableLoading(false)
      getError(t('global.notifications.updateUserErrorUnexpected'))
      console.error('error updating user', error)
    }
  }

  interface LangProps {
    language: string
    onChange: (event: SelectChangeEvent) => void
  }

  const handleLanguageChange = (userId: number | undefined, newLanguage: string) => {
    if (userId) {
      setUserLanguages((prevLanguages) => ({
        ...prevLanguages,
        [userId]: newLanguage,
      }))
      updateLanguage(processId, userId, newLanguage)

      setAddedUsers((prev) => {
        const userIndex = prev.findIndex((user) => user.id === userId)
        if (userIndex !== -1) {
          const newUsers = [...prev]

          const updatedUser = { ...newUsers[userIndex], language: newLanguage }
          newUsers[userIndex] = updatedUser

          dispatch(setUserListAdded(newUsers))

          return newUsers
        }
        return prev
      })
    }
  }

  const handleLanguageChangeAll = (newLanguage: string) => {
    // Actualiza el estado 'userLanguages' para todos los usuarios agregados
    setUserLanguages((prevLanguages) => {
      const updatedLanguages = { ...prevLanguages }
      setAddedUsers((prev) => {
        const newUsers = prev.map((user) => ({
          ...user,
          language: newLanguage,
        }))

        dispatch(setUserListAdded(newUsers))

        // Actualiza 'updatedLanguages' basado en los nuevos usuarios
        newUsers.forEach((user) => {
          updatedLanguages[user.id] = newLanguage
        })

        return newUsers
      })
      return updatedLanguages
    })

    // Llama a 'updateLanguage' para cada usuario agregado
    setAddedUsers((prev) => {
      prev.forEach((user) => {
        updateLanguage(processId, user.id, newLanguage)
      })
      return prev // No es necesario modificar el array de usuarios aquí
    })
  }

  const LanguageSwitch: FC<LangProps> = ({ language, onChange }) => {
    return (
      <Select
        onChange={onChange}
        value={language}
        size="small"
        defaultValue="es"
        sx={{ borderRadius: '8px', width: '110px' }}
      >
        <MenuItem value="es">Español</MenuItem>
        <MenuItem value="en">Inglés</MenuItem>
      </Select>
    )
  }

  const dataSource = results && results.length > 0 ? results : paginatedAddedUsers

  const arrayAddedUsers = dataSource.map((user) => {
    const currentLanguage = userLanguages[user.id] || user.language || 'es'

    return {
      ...user,
      first_name: user.first_name,
      last_name: user.last_name,
      document: user.document,
      email: user.email,
      language: (
        <LanguageSwitch language={currentLanguage} onChange={(e) => handleLanguageChange(user.id, e.target.value)} />
      ),
      edit: (
        isEditing: boolean,
        onEdit: () => void,
        onSave: (rowIndex: number) => void,
        onCancel: () => void,
        rowIndex: number
      ) => {
        if (isEditing) {
          return (
            <ButtonsContainer>
              <ButtonSave onClick={() => onSave(rowIndex)}>Guardar</ButtonSave>
              <ButtonCancel onClick={onCancel}>Cancelar</ButtonCancel>
            </ButtonsContainer>
          )
        }
        return <ButtonEdit onClick={onEdit}>Editar</ButtonEdit>
      },
    }
  })

  const disableMailEdit = () => {
    const isUserEnglish = addedUsers.some((user) => user.language === 'en')
    return isUserEnglish
  }

  return (
    <MainLayout>
      <Container>
        <H2 margin="0px 0px 20px 0px">Resumen</H2>
        <SectionOne>
          <SectionOneItem>
            <SectionOneItemNumber>{getCountQuestionnaires(stepThreeData)}</SectionOneItemNumber>
            <SectionOneItemDescription>Pruebas a utilizar por usuario</SectionOneItemDescription>
          </SectionOneItem>
          {uploadType || resultsSucess.length ? (
            <>
              <HorizontalDivider />
              <SectionOneItem>
                <SectionOneItemNumber>{resultsSucess.length}</SectionOneItemNumber>
                <SectionOneItemDescription>Usuarios creados exitosamente</SectionOneItemDescription>
              </SectionOneItem>
            </>
          ) : null}
          <HorizontalDivider />
          <SectionOneItem style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <SectionOneItemNumber>{inventoryUniqName || inventoryData?.subscriptionName}</SectionOneItemNumber>
            <SectionOneItemDescription style={{ alignSelf: 'center' }}>Inventario utilizado</SectionOneItemDescription>
          </SectionOneItem>
          <HorizontalDivider />
          <SectionOneItem>
            <SectionOneItemNumber>
              {inventoryData?.subscriptionId === 3 || inventoryData?.subscriptionId === 4 ? 'Ilimitado' : amountInventory}
            </SectionOneItemNumber>
            <SectionOneItemDescription>Cantidad de pruebas restantes de tu inventario</SectionOneItemDescription>
          </SectionOneItem>
          {resultsFail.length > 0 || (uploadType && !tryFlag && resultsFail.length) ? (
            <>
              <HorizontalDivider />
              <SectionOneItem
                onClick={() => {
                  navigate(platformAdminRoutes.NotUpload, { state: { data: usersFailed } })
                }}
              >
                <SectionOneItemNumber style={{ color: '#E44646' }}>{resultsFail.length}</SectionOneItemNumber>
                <SectionOneItemDescription style={{ color: '#E44646' }}>Usuarios no creados</SectionOneItemDescription>
              </SectionOneItem>
            </>
          ) : null}
        </SectionOne>
        <h5 style={{ margin: 5 }}>Lista de usuarios agregados</h5>
        <Row gap="20px" justifyContent="center" alignItems="center">
          <P style={{ width: '200px' }}>Buscar por:</P>
          <Input
            name="identification"
            type="text"
            label="Número de identificación"
            value={identification}
            onChange={(e) => setIdentification(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setSearchOnEnter(true)
              }
            }}
          />
          <Input
            name="email"
            type="text"
            label="Correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setSearchOnEnter(true)
              }
            }}
          />
          <Input
            name="name"
            type="text"
            label="Nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setSearchOnEnter(true)
              }
            }}
          />
          <HighlightOffIcon
            style={{ cursor: 'pointer', color: 'gray' }}
            onClick={() => {
              clearSearch()
              setName('')
              setEmail('')
              setIdentification('')
            }}
          />
          <SearchButton
            onClick={() =>
              searchUsers({
                identification,
                name,
                email,
              })
            }
            style={{ fontSize: 14 }}
          >
            <MagnifyingGlass src={IconMagnifying} />
          </SearchButton>
        </Row>
        <BluesiteTableComponent
          editable={true}
          ActionComponent={ActionDeleteUser}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          data={arrayAddedUsers}
          headers={HEADERS_ADDED_USERS_EDITABLE}
          searchBar={false}
          searchLabel=""
          handlePagination={results.length ? handlePaginationResults : handlePagination}
          totalPages={results.length ? Math.ceil(results.length / 10) : totalPages}
          currentPage={results.length ? pageResults : pageUsersAdded}
          onSave={handleSave}
          loading={tableLoading}
          showNumbers={true}
          languageHeaderOption={handleLanguageChangeAll}
        />
        {!disableMailEdit() ? (
          <>
            <Row gap="20px" alignItems="center" justifyContent="space-between">
              <h5 style={{ margin: 5 }}>Correo electrónico enviado a usuarios</h5>
              <StrokedButton
                onClick={() => {
                  navigate('/create-process/update-email')
                }}
              >
                Editar correo
              </StrokedButton>
            </Row>
            <P>Edita el correo electrónico enviado a los participantes de este proceso, este paso es opcional</P>
            {templateEmailStepFive?.textOne && (
              <TextAreaDiv>
                <p>Hola, Nombre de Usuario. Nos alegra saludarte.</p>
                <p>{templateEmailStepFive?.textOne}</p>
                <p>
                  Para ingresar a la plataforma Bluesite y diligenciar los cuestionarios asignados,{' '}
                  {templateEmailStepFive?.textTwo}
                </p>
                <ul>
                  <li>Ingresa a este enlace</li>
                  <li>Sigue las instrucciones para crear una contraseña y activar tu cuenta.</li>
                  <li>Encontrarás los cuestionarios por diligenciar. Haz clic para iniciar</li>
                </ul>

                <p>
                  La fecha límite para que actives tu cuenta y llenes los cuestionarios es el{' '}
                  {templateEmailStepFive?.date}.
                </p>

                <p>{templateEmailStepFive?.textThree}</p>
                <ul>
                  {templateEmailStepFive?.textThreeOptions?.map((option: string, index: number) => (
                    <li key={index}>{option}</li>
                  ))}
                </ul>

                <p>
                  En caso de que llenes parcialmente el cuestionario, o tengas problemas de conectividad, lo puedes
                  retomar más adelante. Para hacerlo, completa los siguientes pasos:
                </p>
                <ul>
                  <li>
                    Ingresa <u>aquí</u>.
                  </li>
                  <li>En el campo de usuario escribe el correo electrónico al que te llegó este mensaje.</li>
                  <li>Digita la contraseña que creaste al momento de crear tu prueba.</li>
                  <li>Reinicia el cuestionario pendiente.</li>
                </ul>

                <p>{templateEmailStepFive?.textFour}</p>

                <p>
                  Puedes ingresar a Bluesite desde cualquier dispositivo. Para una mejor experiencia, por favor utiliza
                  las versiones más recientes de los siguientes navegadores: Chrome, Firefox, Safari.
                </p>
              </TextAreaDiv>
            )}{' '}
          </>
        ) : null}

        <Row gap="20px" alignItems="center" justifyContent="space-between">
          <BackButton onClick={() => navigate(platformAdminRoutes.CreateUsers)} />
          <Button disabled={addedUsers.length < 1} onClick={() => handleSendToUsers()}>
            Enviar pruebas a los participantes
          </Button>
        </Row>
      </Container>
      <ModalLoader isLoading={loading || showModal || loadingSendExamToUser} />
    </MainLayout>
  )
}

export default UsersCreateProcess
