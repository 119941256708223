import styled from 'styled-components'

interface IDiv {
  margin?: string
  justifyContent?: string
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  max-width: 1200px;
  padding: 3.5%;
`

export const RowContainer = styled.div<IDiv>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin: ${(props) => props.margin};
  justify-content: ${(props) => props.justifyContent};
`

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 35px;
`

export const InfoUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
`

export const InfoHalfRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 48%;
  height: 100%;
  border-radius: 10px;
`

export const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const ScoreWrapperInfo = styled(WrapperInfo)`
  width: 50%;
  margin-right: 10%;
`

export const CardInfo = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  flex-direction: column;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin: 20px 0px;
  padding: 20px;
`
export const CardInfoWithBorderTitle = styled.div`
  width: 100%;
  height: 100%;
  min-height: 255px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  flex-direction: column;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px 0px;
`

export const CardInfoWithBorder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background-color: white;
  flex-direction: column;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px 0px;
  padding: 12px 0 32px 12px;
`

export const CardInfoWithTable = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  flex-direction: column;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin: 20px 0px;
`

export const SkillsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
`

export const ReportContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
  height: 100%;
  border-radius: 10px;
`

export const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`

export const SubContainerChart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const CardTitle = styled.div`
  display: flex;
  padding: 3px;
  text-align: center;
  justify-content: center;
`
export const CardTitleWithColor = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 15px;
  background: #e6eef3;
  color: #212b36;
  width: 99%;
  border-radius: 10px 10px 0 0;
  padding: 3px;
`
export const CardTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`
export const TableHead = styled.thead`
  text-align: center;
  padding: 5px;
`
export const TableBody = styled.tbody``
export const NumberRow = styled.tr`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  color: #2d749c;
`
export const TableList = styled.ul`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;

  color: #212b36;
`
export const HeadLeftCell = styled.td`
  border-bottom: 1px solid #919eab;
  border-right: 1px solid #919eab;
`
export const HeadRightCell = styled.td`
  border-bottom: 1px solid #919eab;
  border-left: 1px solid #919eab;
  border-spacing: 0;
`
export const LeftCell = styled.td`
  border-right: 1px solid #919eab;
`
export const RightCell = styled.td`
  border-left: 1px solid #919eab;
`

export const ScoreSectionWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: auto;
`

export const RowTextWrapper = styled(RowContainer)``
export const RowCardsWrapper = styled(RowContainer)`
  justify-content: space-between;
`

export const ScoreTitle = styled.h3`
  margin-bottom: 0px;
  margin-top: 2.5rem;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  letter-spacing: 0.00735em;
  color: #2d749c;
`

export const Subtitle = styled.p`
  margin-right: 4px;
  margin-bottom: 0px;
  font-size: 16px;
  color: #212b36;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.00735em;
  color: #000000;
`

export const InfoText = styled(Subtitle)`
  margin-right: 24px;
  font-weight: 400;
`

export const AdjustmentLevelText = styled(InfoText)`
  text-transform: capitalize;
`

export const WrapperQuadrantList = styled.ul`
  margin: 15px 0 10px 0;
`
export const CountStressorsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #919eab;
`
export const BlueText = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  color: #2d749c;
`

export const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  margin-bottom: 40px;
`

export const TextDownload = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #2d749c;
  margin: 0px 0px 0px 8px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3% 0% 3% 3%;
`

export const DevelopmentButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 30px;
`
