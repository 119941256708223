import { useState, useEffect, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { MainLayout } from 'views/layouts'
import {
  Container,
  LitleCard,
  CountTestAvailables,
  TextLitleCard,
  BigCard,
  MyProcessContainer,
  BigContainer,
  LitleCardsContainer,
  TopContainer,
  ChartContainer,
  MyProcessBottomContainer,
} from './styled'
import { P, SubtitleBold } from 'views/components/UI/Text'
import Select from 'views/components/UI/Select'
import { Button } from 'views/components/UI/Buttons'
import Doughnut from 'views/components/Graphics/Doughnut'
import { platformAdminRoutes } from 'router/routes'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { useFetchAllProcesses } from 'hooks/process/useGetAllProcessCurrentCompany/useGetAllProcessCurrentCompany'
import { useGetResumeProcess } from 'hooks/process/useGetResumeProcess/useGetResumeProcess'
import { ItemDraftProcesses, ItemSendedProcess } from './components'
import { cleanProcess } from 'ducks/createProcess'
import { useNotification } from 'lib/context/notification.context'
import { useFinishProcess } from 'hooks/process/useFinishProcess/useFinishProcess'
import { getProcessDetail } from 'services/common/process/createProcess/create-process-service'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const HomePlatformAdministrator = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { getError, getWarning } = useNotification()
  const { allProcess, fetchAllProcesses, loadingAllProcesses } = useFetchAllProcesses()

  const { handleFinishProcess } = useFinishProcess()

  const { loadingDetailProcess, processInfo, testPercentages, fetchProcessData } = useGetResumeProcess()
  const [process, setProcess] = useState<number | ''>('')
  const [loadingFinishProcess, setLoadingFinishProcess] = useState(false)
  const [useProcess, setUseProcess] = useState<number | number[]>()
  const [allProcessSelect, setAllProcessSelect] = useState([] as any)

  useEffect(() => {
    if (process === '') {
      fetchAllProcesses()
    }
  }, [process, fetchAllProcesses])

  useEffect(() => {
    const processIDs = allProcess?.map((processFilter) => processFilter.value)
    const allProcessFiltered = allProcess?.filter(
      (item: any, index, self) => index === self.findIndex((ts: any) => ts.process_id === item.process_id)
    )
    setAllProcessSelect(allProcessFiltered)
    const numberProcessIDs = processIDs?.map((id) => Number(id))
    const processToSend = !process ? numberProcessIDs : process

    if (processIDs?.length === 0 || !processToSend) {
      return
    }
    setUseProcess(processToSend)
    fetchProcessData(processToSend)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProcess, fetchProcessData, process])

  const handleCreateProcess = () => {
    dispatch(cleanProcess())
    setTimeout(() => {
      navigate(platformAdminRoutes.CreateProcess)
    }, 1000)
  }

  const onFinishProcess = useCallback(
    (processId: number) => {
      if (!loadingFinishProcess) {
        setLoadingFinishProcess(true)
        getProcessDetail(processId)
          .then((response: any) => {
            setLoadingFinishProcess(false)
            handleFinishProcess(response.process)
          })
          .catch(() => {
            getError('Ha ocurrido un error al cargar este proceso')
            setLoadingFinishProcess(false)
          })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getError]
  )

  const handleSelectChange = (e: any) => {
    if (e) {
      setProcess(parseInt(e))
    } else {
      setProcess('')
    }
  }

  const handleSelectClick = () => {
    if (allProcess.length === 0) {
      getWarning('No tienes procesos asignados')
    }
  }

  let processesToShow: Array<{
    process_id: number
    process_name: string
    active_process: boolean
    testId: number
    test_name: string
  }> = []

  if (processInfo && processInfo.all_processes.length > 0) {
    processesToShow = _.uniqBy(processInfo.all_processes, 'process_id')
    processesToShow = processesToShow.slice(0, 10)
  }

  console.log('processInfo', processInfo)

  return (
    <MainLayout>
      {loadingAllProcesses || loadingDetailProcess ? (
        <ModalLoader isLoading={loadingAllProcesses || loadingDetailProcess} />
      ) : (
        <Container>
          <TopContainer>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10, width: 430, alignItems: 'center' }}>
              <SubtitleBold>Visualizar:</SubtitleBold>
              <Select
                label="Todos los procesos"
                options={allProcessSelect || []}
                value={process}
                onChange={(e) => handleSelectChange(e)}
                onClick={() => handleSelectClick()}
              />
            </div>
            <Button onClick={handleCreateProcess} type="submit">
              Nuevo proceso
            </Button>
          </TopContainer>
          <LitleCardsContainer>
            <Link to={platformAdminRoutes.Inventory} state={process ? { processSelectedId: process } : {}}>
              <LitleCard>
                {process ? (
                  <>
                    <TextLitleCard>Pruebas disponibles</TextLitleCard>
                    <CountTestAvailables>
                      {processInfo?.total_inventories && processInfo?.isUnlimited
                        ? 'Ilimitado'
                        : processInfo?.total_inventories || '0'}
                    </CountTestAvailables>
                  </>
                ) : null}
                <TextLitleCard color="#C6016B">Revisa el estado de tus inventarios</TextLitleCard>
              </LitleCard>
            </Link>
          </LitleCardsContainer>
          <BigContainer>
            <BigCard>
              <div style={{ marginBottom: '20px', zIndex: 3 }}>
                <span style={{ fontWeight: 700, fontSize: 18, color: '#1F2124', textAlign: 'left' }}>
                  Resumen de pruebas
                </span>
              </div>
              {processInfo && (
                <ChartContainer>
                  <Doughnut
                    dataset={[
                      processInfo?.total_tests_generated?.betesaPercent,
                      processInfo?.total_tests_generated?.skillPercent,
                    ]}
                    type="summary"
                    labels={[
                      `Betesa (${testPercentages.betesaPercent}%)`,
                      `${t('global.common.skillText')} (${testPercentages.skillPercent}%)`,
                    ]}
                    totalTests={processInfo?.total_tests_generated?.total}
                  />
                </ChartContainer>
              )}
            </BigCard>
            <BigCard>
              <div style={{ height: '40px', zIndex: 3 }}>
                <span style={{ fontWeight: 700, fontSize: 18, color: '#1F2124', textAlign: 'left' }}>
                  Pruebas totales contestadas vs. total
                </span>
              </div>
              {processInfo && (
                <ChartContainer>
                  <Doughnut
                    dataset={[processInfo?.total_tests_generated.total, processInfo?.total_tests_completed]}
                    type="comparison"
                    labels={[
                      `Pruebas generadas (${processInfo?.total_tests_generated.total})`,
                      `Pruebas contestadas (${processInfo?.total_tests_completed})`,
                    ]}
                    totalTests={processInfo?.total_tests_completed}
                  />
                </ChartContainer>
              )}
            </BigCard>

            <MyProcessContainer>
              <span style={{ fontWeight: 700, fontSize: 18, color: '#1F2124', textAlign: 'left', margin: '10px 0px' }}>
                Mis procesos
              </span>
              {processInfo && processInfo.all_processes.length > 0 ? (
                <div
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}
                >
                  {_.uniqBy(processesToShow, 'process_id').map((item, indexItem) => {
                    const Component = item.active_process ? ItemSendedProcess : ItemDraftProcesses
                    return (
                      <Component
                        onFinishProcess={onFinishProcess}
                        processId={item.process_id}
                        name={item.process_name}
                        key={`${indexItem + item.process_name}`}
                      />
                    )
                  })}
                </div>
              ) : (
                <P>No existen procesos asociados a esta compañía.</P>
              )}
              <MyProcessBottomContainer>
                <Link to={platformAdminRoutes.MyProcess} state={useProcess}>
                  <Button type="submit">Ver más</Button>
                </Link>
              </MyProcessBottomContainer>
            </MyProcessContainer>
          </BigContainer>
        </Container>
      )}
    </MainLayout>
  )
}

export default HomePlatformAdministrator
